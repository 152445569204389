'use strict';

import { depotSimpleState, contributionModificationState, contributionRedirectionState } from './depot-states';

angular.module('portailDepotDemandeAide.depot', ['ui.router', 'ngMessages', 'aides']);

angular.module('portailDepotDemandeAide.depot').config([
  '$stateProvider',
  function ($stateProvider) {
    'use strict';

    $stateProvider
      .state('app.connected.config', {
        // This is a abstract state to share some logic between all states that depend on a configuration (=téléprocédure)
        url: '/:configurationId',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          configurationId: [
            '$stateParams',
            function ($stateParams) {
              return $stateParams.configurationId;
            },
          ],

          tiers: [
            'tiersService',
            'mdm',
            'selectedTiers',
            function (tiersService, mdm, selectedTiers) {
              return selectedTiers ?? tiersService.getCurrentTiers(mdm, 'famille');
            },
          ],
        },
      })
      .state('app.connected.config.depot', {
        // Shared logic accross all pages of any workflow, mostly a rich template
        url: '/depot',
        controller: 'depotController',
        template: '<div ui-view></div>',
        abstract: true,
        resolve: {
          teleserviceConfiguration: [
            '$stateParams',
            '$translate',
            '$location',
            'teleservicesService',
            'configuration',
            function ($stateParams, $translate, $location, teleservicesService, configuration) {
              // If we have an id persistence, we retrieve the teleservice same if it's disable
              var persistence = $location.search().p ? true : false;

              return teleservicesService
                .getTeleService($stateParams.configurationId, persistence, { expand: 'thematiques' })
                .then(function (teleservice) {
                  // Modify by reference i18n's configuration and force the refresh.
                  // Necessary to fetch files with dynamic names based on current teleservice
                  configuration.i18n.teleservice.id = $stateParams.configurationId.split('?')[0];

                  if (!teleservice) {
                    return $translate.refresh().then(function () {
                      return {};
                    });
                  }

                  if (teleservice.workflow.type) {
                    configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                    return $translate.refresh().then(function () {
                      teleservice.workflow = teleservice.workflow[configuration.i18n.teleservice.workflow];
                      // copy type to control access in controller
                      teleservice.workflow.type = configuration.i18n.teleservice.workflow;
                      return teleservice;
                    });
                  } else {
                    configuration.i18n.teleservice.workflow = teleservice.typeWorkflow;
                    return teleservice;
                  }
                });
            },
          ],
          publicSettingsTiers: [
            'tiersService',
            'adresseService',
            function (tiersService, adresseService) {
              return tiersService.getPublicSettingsTiers().then(function (publicSettingsTiers) {
                // Configure api ban
                adresseService.setApiBan(
                  _.get(publicSettingsTiers, 'remoteServices.apiban.active', false),
                  _.get(publicSettingsTiers, 'remoteServices.apiban.url')
                );

                return publicSettingsTiers;
              });
            },
          ],
        },
      })
      .state('app.connected.config.depot.simple', depotSimpleState)
      .state('app.connected.config.depot.demandePaiement', {
        // Procedure model 'demandePaiement', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/:referenceAide/demandePaiement?reference&financeurHref&dossier',
        controller: 'demandePaiementController',
        templateUrl: 'depot/demande-paiement/demande-paiement.html',
        dynamic: true, // ?reference will be set after some time, do not reload the state for it
        params: {
          aide: null,
        },

        resolve: {
          aide: [
            '$stateParams',
            'aidesService',
            '$state',
            '$translate',
            function ($stateParams, aidesService, $state, $translate) {
              return aidesService
                .getForPaiements($stateParams.referenceAide, {
                  headers: {
                    'X-No-Interceptor': true,
                  },
                })
                .then((aide, error) => {
                  if (error) {
                    const errorMessage = $translate.instant('connected.depot.errors.unknown');

                    NotificationCenter.postMessage({
                      content: errorMessage,
                      variant: 'danger',
                    });

                    $state.go('app.connected.dashboard.accueil', { tenantId: $stateParams.tenantId });
                    throw Error(errorMessage);
                  }
                  if (aide.cloture?.value) {
                    NotificationCenter.postMessage({
                      content: $translate.instant('connected.depot.errors.demandePaiementCannotBeCreatedOrUpdate'),
                      variant: 'danger',
                    });
                    $state.go($state.current);

                    throw Error($translate.instant('connected.depot.errors.demandePaiementCannotBeCreatedOrUpdate'));
                  } else {
                    return aide;
                  }
                });
            },
          ],

          demandePaiement: [
            '$stateParams',
            'demandesPaiementService',
            'aide',
            'teleserviceConfiguration',
            'StoreService',
            '$state',
            '$translate',
            function (
              $stateParams,
              demandesPaiementService,
              aide,
              teleserviceConfiguration,
              StoreService,
              $state,
              $translate
            ) {
              var teleserviceUrl = teleserviceConfiguration.id;

              if ($stateParams.reference) {
                const promise = demandesPaiementService
                  .getDemandePaiement($stateParams.reference, {
                    params: {
                      $expand: 'pieces.documents,domiciliationBancaire.pieces.documents,attributaire',
                    },
                    headers: {
                      'X-No-Interceptor': true,
                    },
                  })
                  .then(function (demandePaiement) {
                    demandePaiement.teleservicePaiement = {
                      id: teleserviceUrl,
                      href: teleserviceUrl,
                      title: teleserviceConfiguration.title,
                      expand: teleserviceConfiguration,
                    };

                    demandePaiement.demandeFinancement = {
                      href: aide.id,
                      expand: aide,
                    };

                    return demandePaiement;
                  });
                StoreService.demandePaiement.pendingPromises.push(promise);
                return promise.catch(() => {
                  const errorMessage = $translate.instant('connected.depot.errors.unknown');

                  NotificationCenter.postMessage({
                    content: errorMessage,
                    variant: 'danger',
                  });

                  $state.go('app.connected.dashboard.accueil', { tenantId: $stateParams.tenantId });
                  throw Error(errorMessage);
                });
              } else {
                return {
                  statut: 'EN_COURS',
                  teleservicePaiement: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    expand: teleserviceConfiguration,
                  },
                  demandeFinancement: {
                    href: aide.id,
                    expand: aide,
                  },
                };
              }
            },
          ],

          typesPaiement: [
            'paiementService',
            'demandePaiement',
            function (paiementService, demandePaiement) {
              if (_.get(demandePaiement, 'teleservicePaiement.expand')) {
                return demandePaiement.teleservicePaiement.expand.workflow.pageInformationsGenerales.typesPaiement;
              }
              return paiementService.getTypesPaiement(demandePaiement.teleservicePaiement.id);
            },
          ],

          informationsComplementaires: [
            'teleserviceConfiguration',
            'dataSchemasService',
            function (teleserviceConfiguration, dataSchemasService) {
              // Get list of informations complementaires data-schemas from teleservice paiement
              const infosCompHrefList = JSONPath(
                "$.[?(@.page == 'pageInformationsComplementaires')].schema.href",
                _.get(teleserviceConfiguration, 'views')
              );

              const infosComplementaires = dataSchemasService
                .getDataSchemasList(infosCompHrefList)
                .then((infosComp) => {
                  return infosComp;
                });
              return infosComplementaires;
            },
          ],

          decisions: [
            '$stateParams',
            'aidesService',
            'aide',
            function ($stateParams, aidesService) {
              return aidesService.getDecisionsHistory($stateParams.referenceAide, $stateParams.dossier);
            },
          ],

          pagesToDisplay: [
            '$q',
            'aide',
            'demandesPaiementService',
            'informationsComplementaires',
            'indicateursService',
            'teleserviceConfiguration',
            function (
              $q,
              aide,
              demandesPaiementService,
              informationsComplementaires,
              indicateursService,
              teleserviceConfiguration
            ) {
              // True if the "indicateur" page is active on the "demande paiement" workflow
              const pageIndicateurIsActif = teleserviceConfiguration?.workflow?.pageIndicateurs?.actif;

              // True if the "informations-complementaires" page is active on the "demande paiement" workflow
              const pageInformationsComplementairesIsActif =
                teleserviceConfiguration?.workflow?.pageInformationsComplementaires?.actif;

              return $q
                .all([
                  indicateursService.showIndicateur(aide),
                  demandesPaiementService.mustDisplayInformationsComplementaires(informationsComplementaires),
                ])
                .then(([displayIndicateurs, displayInformationsComplementaires]) => {
                  return {
                    indicateurs: displayIndicateurs && pageIndicateurIsActif,
                    informationsComplementaires:
                      displayInformationsComplementaires && pageInformationsComplementairesIsActif,
                  };
                });
            },
          ],
          lockingSocket: [
            '$state',
            '$stateParams',
            '$translate',
            'lockEntitiesService',
            'demandePaiement',
            'aide',
            ($state, $stateParams, $translate, lockEntitiesService, demandePaiement, aide) => {
              return lockEntitiesService.getLockingSocket(demandePaiement, 'demandes-paiement').catch((err) => {
                $state.go('app.connected.dashboard.aides.demandesPaiement.recapitulatif', {
                  reference: aide.reference,
                  referencePaiement: demandePaiement.reference,
                  teleservicePaiement: demandePaiement.teleservicePaiement.reference,
                  tenantId: $stateParams.tenantId,
                });
                NotificationCenter.postMessage({
                  content: $translate.instant('connected.depot.errors.demandeLockedByAnotheruser', err.data),
                  variant: 'danger',
                });
              });
            },
          ],
        },
      })
      .state('app.connected.config.depot.contributions', {
        url: '/contributions/:contributionRef',
        component: 'contributionAvisComponent',
        resolve: {
          // Contribution
          contribution: [
            '$stateParams',
            'contributionsService',
            function ($stateParams, contributionsService) {
              return contributionsService.get($stateParams.contributionRef);
            },
          ],

          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            function (aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],

          demande: [
            'contribution',
            function (contribution) {
              return _.get(contribution, 'demande', null);
            },
          ],
        },
      })
      .state('app.connected.config.depot.contributionRedirection', contributionRedirectionState)
      .state('app.connected.config.depot.contributionModification', contributionModificationState)
      .state('app.connected.config.depot.contributionInformation', {
        url: '',
        abstract: true,
      })
      .state('app.connected.config.depot.contributionInformation.demandeFinancement', {
        url: '/demandes-financement/:demandeFinancementReference/contributions-information/:contributionInformationReference',
        component: 'contributionInformationComponent',
      })
      .state('app.connected.config.depot.contributionInformation.demandeApprenant', {
        url: '/demandes-apprenants/:demandeFinancementReference/contributions-information/:contributionInformationReference',
        component: 'contributionInformationComponent',
      });
  },
]);
