'use strict';
angular.module('common.services').factory('routeUtilsService', [
  '$rootScope',
  'IFrameCommunicationManager',
  '$timeout',
  '$log',
  '$state',
  'types',
  '$modal',
  '$uibModal',
  '$translate',
  'shareEntityService',
  'justificationsService',
  'aidesService',
  'demandesAidesService',
  'configuration',
  'contributionsService',
  function (
    $rootScope,
    IFrameCommunicationManager,
    $timeout,
    $log,
    $state,
    types,
    $modal,
    $uibModal,
    $translate,
    shareEntityService,
    justificationsService,
    aidesService,
    demandesAidesService,
    configuration,
    contributionsService
  ) {
    'use strict';
    const iFrameCommunicationManager = new IFrameCommunicationManager();
    $rootScope.manageRightModal = {};

    /**
     * ensure valid params on redirect
     *
     * @param {string} toLocation state to name
     * @param {string} toParams state to params in json string form
     * @returns {object} parsed params
     */
    function ensureValidParams(toLocation, toParams) {
      let to = toLocation;
      let params = {};
      try {
        params = JSON.parse(toParams);
      } catch (err) {
        $log.error(err);
        to = 'app.connected.dashboard.accueil';
      }
      return { toLocation: to, toParams: params };
    }

    return {
      /**
       * Redirects to the "redirectTo" param if set or go to given destination
       * otherwise
       *
       * @param {string} destination destination if there is no redirection set
       * @param {object} options state go options
       * @returns {void}
       */
      handleRedirectionOrGoTo(destination, options) {
        if ($state.params.redirectTo) {
          this.manageRedirection(options);
        } else {
          $state.go(destination, {}, options);
        }
      },

      /**
       * Manage redirection
       *
       * @param {object} options state go options
       * @returns {void}
       */
      manageRedirection: function (options) {
        const { toLocation, toParams } = ensureValidParams($state.params.redirectTo, $state.params.redirectParams);
        // Redirection after home page authentification
        $state.go(toLocation, toParams, options);
      },

      /**
       * Redirect to home
       *
       * @param {string} name state name
       * @param {object} toParams state to parameters
       * @returns {void}
       */
      redirectToHome: function (name, toParams) {
        const askLocation = name;
        const askParams = JSON.stringify(toParams);
        $state.go('app.home', {
          tenantId: toParams.tenantId,
          redirectTo: askLocation,
          redirectParams: askParams,
        });
      },

      //==================================
      // Demande Financement
      //==================================

      /**
       * Go to depot simple
       *
       * @param {string} configurationId
       * @param {string} p
       * @param {object} options
       * @returns {void}
       */
      goToDepotSimple(configurationId, p, options = {}) {
        $state.go('app.connected.config.depot.simple', {
          configurationId,
          p,
          ...options,
        });
      },

      /**
       * Go to recapitulatif demande financement
       *
       * @param {string} aide
       * @param {object} options
       * @returns {void}
       */
      goToRecapDemandeFinancement(aide, options = {}) {
        $state.go('app.connected.dashboard.recapitulatif', {
          aide,
          ...options,
        });
      },

      /**
       * Go to deposer demande aides
       *
       * @returns {void}
       */
      goToDeposerDemandeAide() {
        $state.go('app.connected.dashboard.deposerDemandeAide');
      },

      /**
       * Go to demandes list
       *
       * @returns {void}
       */
      goToDemandesList() {
        $state.go('app.connected.dashboard.aides.demandesAides.aides');
      },

      /**
       * Go to shared demandes list
       *
       * @returns {void}
       */
      goToSharedDemandesList() {
        $state.go('app.connected.dashboard.aides.demandesAides.sharedAides');
      },

      /**
       * Go to demande complements (add-pieces)
       *
       * @param {object} aide
       * @returns {void}
       */
      goToDemandeDepotPieces(aide) {
        $state.go('app.connected.dashboard.aides.demandedepotpieces', {
          reference: aide.reference,
        });
      },

      /**
       * Go to create demande paiement
       *
       * @param {object} aide
       * @param {string} dossierReference
       * @param {string} teleservicePaiement
       * @returns {void}
       */
      goToCreateDemandePaiement(aide, dossierReference, teleservicePaiement) {
        $state.go('app.connected.config.depot.demandePaiement', {
          referenceAide: aide.reference,
          configurationId: teleservicePaiement,
          dossier: dossierReference,
          financeurHref: aide.financeurPrincipal?.href,
        });
      },

      /**
       * Go to list of demandes paiement
       *
       * @param {object} aide
       * @param {string} dossierReference
       * @param {string} teleservicePaiement
       * @returns {void}
       */
      goToDemandePaiements(aide, dossierReference, teleservicePaiement) {
        $state.go('app.connected.dashboard.aides.demandesPaiement.list', {
          reference: aide.reference,
          aide,
          teleservicePaiement,
          dossier: dossierReference,
        });
      },

      /**
       * Go to contribution information edition page
       *
       * @param {string} teleserviceContributionInformationReference reference of the teleservice
       * @param {string} demandeFinancementReference reference of the demande
       * @param {string} contributionInformationReference reference of the contribution information
       * @returns {void}
       */
      goToContributionInformationEdit(
        teleserviceContributionInformationReference,
        demandeFinancementReference,
        contributionInformationReference
      ) {
        $state.go('app.connected.config.depot.contributionInformation.demandeFinancement', {
          configurationId: teleserviceContributionInformationReference,
          demandeFinancementReference,
          contributionInformationReference,
        });
      },

      /**
       * Open max number demandes reached modal
       *
       * @param {object} scopeModal
       * @returns {void}
       */
      modalMaxNumberPersistenceReach(scopeModal) {
        $modal({
          scope: scopeModal,
          template: 'dashboard/aides/modal/modal-maxnumber-demandes.html',
        });
      },

      /**
       * Show an error alert
       *
       * @param {object} error
       * @param {string} error.data
       * @returns {void}
       */
      showErrorAlert({ data = '' }) {
        NotificationCenter.postMessage({
          content: `${$translate.instant('common.error.happened')} ${data}`,
          variant: 'danger',
        });
      },

      /**
       * Duplication of demande-aide
       *
       * @param {object} demande the demande object
       * @param {object} scopeModal
       * @returns {Promise<void>}
       */
      duplicateDemande(demande, scopeModal) {
        return demandesAidesService
          .duplicateDemand({ ...demande, tenant: configuration.tenant.id })
          .then((aideDuplicated) => {
            this.goToDepotSimple(demande?.teleservice?.reference, aideDuplicated.reference);
          })
          .catch((err) => {
            const data = _.get(err, 'data', {});
            if (err.status === 403 && data.error.code === 403.1 && data.error.maxPersistenceNumber) {
              this.modalMaxNumberPersistenceReach(scopeModal);
            } else if (data.message) {
              $log.error(data.message);
              this.showErrorAlert();
            }
          });
      },

      /**
       * Delete a demande-aide
       *
       * @param {object} modal modal to confirm demande deletion
       * @param {object} aide aide
       * @param {object} scope scope
       * @returns {Promise<void>}
       */
      confirmDeleteDemande(modal, aide, scope) {
        modal.$hide();
        return aidesService
          .remove(aide)
          .then(() => {
            scope.$emit('delete-demande', aide);
            NotificationCenter.postMessage({
              content: $translate.instant('connected.dashboard.aides.demandesAides.deleted'),
              variant: 'success',
            });
            iFrameCommunicationManager.sendEvent({
              action: 'demande-financement/delete-item',
              id: aide.id,
            });
          })
          .catch((error) => {
            this.showErrorAlert(error);
          });
      },

      /**
       * Show the share modal
       *
       * @param {string} kind the entity kind
       * @param {object} entity the entity object
       * @returns {void}
       */
      openShareModal(kind, entity) {
        $rootScope.$broadcast('share-entity', entity);
        const closeSharingsModalListener = $rootScope.$on('close-sharings-modal', () => {
          iFrameCommunicationManager.sendEvent({
            action: `${kind}/close-sharings-modal`,
            id: entity.id,
            demandeId: entity.demande?.id,
          });
          // Call listener functions to remove listeners (we don't want to be subscribed anymore because modal was closed)
          closeSharingsModalListener();
          entitySharedListener();
        });
        const entitySharedListener = $rootScope.$on('entity-shared', (_event, sharings) => {
          iFrameCommunicationManager.sendEvent({
            action: `${kind}/update-sharings`,
            id: entity.id,
            demandeId: entity.demande?.id,
            sharings,
          });
          if (kind !== 'demande-financement') {
            // When a sub-entity of a demande-financement is shared, we update also the global demande-financement sharings counter
            shareEntityService.getSharings(entity.demande).then((demandeSharings) => {
              iFrameCommunicationManager.sendEvent({
                action: 'demande-financement/update-sharings',
                id: entity.demande.id,
                sharings: demandeSharings,
              });
            });
          }
          // Call listener functions to remove listeners (we don't want to be subscribed anymore because modal was closed)
          closeSharingsModalListener();
          entitySharedListener();
        });
      },

      /**
       * Handle routing
       * Demande Financement
       *
       * @param {string} action
       * @param {object} value
       * @param {object} scope
       * @returns {void}
       */
      goTo(action, value, scope) {
        const scopeModal = scope.$new();

        const teleserviceReferenceWithoutRevision = value?.aide?.teleservice?.href?.split('?')[0]?.split('/').pop();

        switch (action) {
          case types.DemandesFinancementActions.RESUME: // resume an uncompleted demande
            this.goToDepotSimple(teleserviceReferenceWithoutRevision, value?.aide.reference);
            break;
          case types.DemandesFinancementActions.ACCESS: // go to demande details
            this.goToRecapDemandeFinancement(
              value?.aide.reference,
              $state.params.page ? { page: $state.params.page } : {}
            );
            break;
          case types.DemandesFinancementActions.DUPLICATE: // duplicate demande
            this.duplicateDemande(value.aide, scope.$new());
            break;
          case types.DemandesFinancementActions.DELETE: // open delete demande confirmation modal
            scopeModal.persistence = value.aide;
            scopeModal.confirmDeleteDemande = (modal, aide) => this.confirmDeleteDemande(modal, aide, scope);
            $modal({
              scope: scopeModal,
              template: 'dashboard/aides/modal/modal-delete-demande.html',
            });
            break;
          case types.DemandesFinancementActions.SHARE: // on click dropdown "Partager" action
            this.openShareModal('demande-financement', value.aide);
            break;
          case types.DemandesFinancementActions.DEPOT_DEMANDE_AIDE: // go to depot demande
            this.goToDeposerDemandeAide();
            break;
          case types.DemandesFinancementActions.DEMANDES_LIST: // go to demandes list
            this.goToDemandesList();
            break;
          case types.DemandesFinancementActions.SHARED_DEMANDES_LIST: // go to shared demandes list
            this.goToSharedDemandesList();
            break;
          case types.DemandesFinancementActions.MANAGE_RIGHTS: // on click dropdown "Gérer l'accès" action
            $rootScope.manageRightModal.demande = value.aide;
            $rootScope.manageRightModal.showRestrictionsModal = true;
            break;
          case types.DemandesFinancementActions.ADD_PIECES: // on click dropdown "add-pieces" action
            this.goToDemandeDepotPieces(value.aide);
            break;
          case types.DemandesFinancementActions.CREATE_PAYMENT: // go to create payment page
            this.goToCreateDemandePaiement(value.aide, value.dossierReference, value.teleservicePaiement);
            break;
          case types.DemandesFinancementActions.ACCESS_PAYMENTS: // on click dropdown "access-payments" action
          case types.DemandesFinancementActions.ACCESS_ALL_PAYMENTS:
            this.goToDemandePaiements(value.aide, value.dossierReference, value.teleservicePaiement);
            break;
          case types.DemandesFinancementActions.CONTRIBUTION_INFORMATION: // go to contribution information
            this.goToContributionInformationEdit(
              value.configurationId,
              value.demandeReference,
              value.contributionInformationReference
            );
            break;
        }
      },

      //==================================
      // sollicitations
      //==================================

      /**
       * Go to complement edition
       *
       * @param {object} value - {configurationId: string, p: }
       * @returns {void}
       */
      goToContributionModification(value) {
        $state.go('app.connected.config.depot.contributionModification', {
          configurationId: value.configurationId,
          p: value.p,
          c: value.c,
        });
      },

      /**
       * Go to complement completion
       *
       * @param {object} value - {configurationId: string, p: }
       * @returns {void}
       */
      goToContributionRedirection(value) {
        $state.go('app.connected.config.depot.contributionRedirection', {
          configurationId: value.configurationId,
          p: value.p,
          c: value.c,
        });
      },

      /**
       * Go to demande upload complement
       *
       * @param {object} value - {reference: string}
       * @returns {void}
       */
      goToAidesComplements(value) {
        $state.go('app.connected.dashboard.aides.complements', { reference: value.reference });
      },

      /**
       * Go to a demande paiement
       *
       * @param {object} value - {reference: string, referencePaiement: string, tsPaiementRef: string, dossierReference: string, financeurHref: string}
       * @returns {void}
       */
      goToDemandePaiement(value) {
        $state.go('app.connected.config.depot.demandePaiement', {
          reference: value.referencePaiement,
          referenceAide: value.reference,
          configurationId: value.tsPaiementRef,
          dossier: value.dossierReference,
          financeurHref: value.financeurHref,
        });
      },

      /**
       * Go to demande paiement upload complement
       *
       * @param {object} value - {reference: string, referencePaiement: string}
       * @returns {void}
       */
      goToDemandePaiementComplements(value) {
        $state.go('app.connected.dashboard.aides.demandesPaiement.complements', {
          reference: value.reference,
          referencePaiement: value.referencePaiement,
        });
      },

      /**
       * Go to demande paiement recapitulatif
       *
       * @param {object} value - {reference: string, referencePaiement: string, tsPaiementRef: string, financeurHref: string}
       * @returns {void}
       */
      goToDemandePaiementRecap(value) {
        $state.go('app.connected.dashboard.aides.demandesPaiement.recapitulatif', {
          reference: value.reference,
          referencePaiement: value.referencePaiement,
          teleservicePaiement: value.tsPaiementRef,
          financeur: value.financeurHref,
        });
      },

      /**
       * Go to avis recapitulatif
       *
       * @param {object} value - {aideRef: string, contributionRef: string, contributionStatus: string}
       * @returns {void}
       */
      goToAvisRecapitulatif(value) {
        this.goToRecapDemandeFinancement(value.aideRef, {
          fromContribution: value.contributionRef,
          statutContribution: value.contributionStatus,
          fromAvis: true,
          activeFilters: value.activeFilters,
        });
      },

      /**
       * Go to avis completed recapitulatif
       *
       * @param {object} value - {aideRef: string, contributionRef: string, contributionStatus: string}
       * @returns {void}
       */
      goToAvisCompletedRecapitulatif(value) {
        this.goToRecapDemandeFinancement(value.aideRef, {
          fromContribution: value.contributionRef,
          statutContribution: value.contributionStatus,
          fromAvisCompleted: true,
          activeFilters: value.activeFilters,
        });
      },

      /**
       * Go to avis depot
       *
       * @param {object} value - {contributionTeleservice: string, contributionRef: string}
       * @returns {void}
       */
      goToAvisDepot(value) {
        $state.go('app.connected.config.depot.contributions', {
          configurationId: value.contributionTeleservice,
          contributionRef: value.contributionRef,
        });
      },

      /**
       * Go to avis prealable edit
       *
       * @param {object} value - {contributionRef: string}
       * @returns {void}
       */
      goToAvisPrealableRecapitulatif(value) {
        $state.go('app.connected.dashboard.contributionsAvisPrealable.avis.recapitulatif', {
          typeAvis: 'avisADonner',
          aide: value.contributionRef, // contributionRef is the demande-financement reference
          activeFilters: value.activeFilters,
        });
      },

      /**
       * Go to avis prealable access demande
       *
       * @param {object} value - {aideRef: string, contributionRef: string, contributionStatus: string}
       * @returns {void}
       */
      goToAvisPrealableAccessDemande(value) {
        this.goToRecapDemandeFinancement(value.aideRef, {
          fromContribution: value.contributionRef,
          statutContribution: value.contributionStatus,
          fromAvisPrealables: true,
          activeFilters: value.activeFilters,
        });
      },

      /**
       * Go to avis contribution recapitulatif
       *
       * @param {object} value - {contributionRef: string}
       * @returns {void}
       */
      goToContributionRecap(value) {
        $state.go('app.connected.dashboard.contributions.recapitulatif', {
          referenceContribution: value.contributionRef,
          activeFilters: value.activeFilters,
        });
      },

      /**
       * Delete contribution
       *
       * @param {object} modal
       * @param {object} contribution
       * @param {object} [contribution.avis]
       * @param {string} contribution.reference
       * @returns {void}
       */
      confirmDeleteContribution(modal, contribution) {
        modal.$hide();

        contributionsService
          .removeAnswer(contribution)
          .then(() => {
            NotificationCenter.postMessage({
              content: $translate.instant('connected.dashboard.contributions.list.delete.success'),
              variant: 'success',
            });
            $timeout(() => {
              iFrameCommunicationManager.sendEvent({
                action: 'refresh',
              });
            });
          })
          .catch(({ data }) => {
            NotificationCenter.postMessage({
              content: `${$translate.instant('sollicitations.avis.delete.error')} ${data}`,
              variant: 'danger',
            });
          });
      },

      /**
       * Delete avis
       *
       * @param {object} contribution partial contribution avis
       * @param {object} [contribution.avis]
       * @param {string} contribution.reference
       * @param {object} scopeModal
       * @returns {void}
       */
      deleteAvisModal(contribution, scopeModal) {
        scopeModal.contribution = contribution;
        scopeModal.confirmDeleteContribution = this.confirmDeleteContribution;

        $modal({
          scope: scopeModal,
          template: 'contributions/contributions-components/contributions-liste/modal/contributions-modal-delete.html',
        });
      },

      /**
       * Go to attestation recapitulatif
       *
       * @param {object} value - {aide: object}
       * @returns {void}
       */
      goToAttestationRecap(value) {
        this.goToRecapDemandeFinancement(value.aide, {
          fromAttestations: true,
        });
      },

      /**
       * Go to justification
       *
       * @param {object} value - {reference: string}
       * @returns {void}
       */
      goToJustification(value) {
        $state.go('app.connected.dashboard.justification', { justificationReference: value.reference });
      },

      /**
       * Display delete justification modal and eventually delete the justification
       *
       * @param {object} value - {reference: string, isUserCreator: boolean}
       * @returns {void}
       */
      deleteJustificationModal(value) {
        const instance = $uibModal.open({
          component: 'justificationsListDeleteModal',
          size: 'md',
          backdrop: 'static',
          resolve: {
            message: () => {
              //the message differs if the current user is the creator of the demande
              const key = value.isUserCreator ? 'owner' : 'other';
              return $translate.instant(`connected.dashboard.aides.search.modal.message.${key}`);
            },
          },
        });
        // catching the result to handle modal.dismiss() as it throw a promise rejection
        instance.result
          .then(() =>
            justificationsService.deleteJustification(value.reference).then(() => {
              $timeout(() => {
                iFrameCommunicationManager.sendEvent({
                  action: 'refresh',
                });
                iFrameCommunicationManager.sendEvent({
                  action: 'remove-justification',
                  data: {
                    id: value.id,
                    reference: value.reference,
                  },
                });
              });
            })
          )
          .catch(() => {
            $timeout(() => {
              iFrameCommunicationManager.sendEvent({
                action: `justification/close-remove-modal`,
                id: value.reference,
                demandeId: value.demandeId,
              });
            });
          });
      },

      /**
       * Go to demande report creation
       *
       * @param {object} value - {reference: string}
       * @returns {void}
       */
      goToDemandeReportCreate(value) {
        $state.go('app.connected.dashboard.saisieDemandeReport', { demandeReference: value.reference });
      },

      /**
       * Handle routing
       * sollicitations
       *
       * @param {object} value
       * @param {object} scopeModal - scopeModal = $scope.$new()
       * @returns {void}
       */
      handleSollicitationActions(value, scopeModal) {
        switch (value.action) {
          case 'complete-modification':
            this.goToContributionModification(value);
            break;
          case 'complete-redirection':
            this.goToContributionRedirection(value);
            break;
          case types.SollicitationsActions.SHARE_CONTRIBUTION:
            this.openShareModal('contribution', value.contribution);
            break;
          case 'dcp-demande-financement-upload':
            this.goToAidesComplements(value);
            break;
          case 'dcp-demande-paiement-upload':
            this.goToDemandePaiementComplements(value);
            break;
          case 'avis-access-demande':
            this.goToAvisRecapitulatif(value);
            break;
          case 'avis-completed-access-demande':
            this.goToAvisCompletedRecapitulatif(value);
            break;
          case 'avis-continue':
          case 'avis-edit':
          case 'avis-completed-edit':
            this.goToAvisDepot(value);
            break;
          case 'avis-prealable-edit':
            this.goToAvisPrealableRecapitulatif(value);
            break;
          case 'avis-prealable-access-demande':
            this.goToAvisPrealableAccessDemande(value);
            break;
          case 'avis-access':
            this.goToContributionRecap(value);
            break;
          case 'avis-delete':
            this.deleteAvisModal({ reference: value.contributionRef, avis: value.contributionAvis }, scopeModal);
            break;
          case 'attestations-access-demande':
            this.goToAttestationRecap(value);
            break;
          case 'justification-created':
          case 'justification-resume':
          case 'justification-edition':
            this.goToJustification(value);
            break;
          case 'justification-delete':
            this.deleteJustificationModal(value);
            break;
          case 'justification-demande-report-create':
            this.goToDemandeReportCreate(value);
            break;
          case 'demande-paiement-edit':
            this.goToDemandePaiement(value);
            break;
          case 'demande-paiement-access':
            this.goToDemandePaiementRecap(value);
            break;
        }
      },

      //==================================
      // Demandes apprenants
      //==================================

      /**
       * Go to recapitulatif demande apprenant
       *
       * @param {string} demandeFinancementReference
       * @param {object} options
       * @returns {void}
       */
      goToRecapDemandeApprenant(demandeFinancementReference, options = {}) {
        $state.go('app.connected.dashboard.demandeApprenantRecapitulatif', { demandeFinancementReference, ...options });
      },

      /**
       * Handle routing
       * Demande Apprenants
       *
       * @param {string} action
       * @param {object} value
       * @param {object} options
       * @returns {void}
       */
      goToDemandesApprenants(action, value, options = {}) {
        switch (action) {
          case types.DemandesApprenantsActions.ACCESS: // go to demande apprenant details
            this.goToRecapDemandeApprenant(value?.demandeApprenant.reference, options);
            break;
          case types.DemandesApprenantsActions.CONTRIBUTION_INFORMATION: // go to contribution information
            this.goToContributionInformationEdit(
              value?.teleserviceContributionInformationReference,
              value?.demandeFinancementReference,
              value?.contributionInformationReference
            );
            break;
        }
      },

      //==================================
      // Entree en formation
      //==================================

      /**
       * Go to recapitulatif entree formation
       *
       * @param {string} demandeFinancementReference
       * @param {object} options
       * @returns {void}
       */
      goToRecapEntreeFormation(demandeFinancementReference, options = {}) {
        $state.go('app.connected.dashboard.entreeFormationRecapitulatif', { demandeFinancementReference, ...options });
      },

      /**
       * Handle routing
       * Demande Apprenants
       *
       * @param {string} action
       * @param {object} value
       * @param {object} options
       * @returns {void}
       */
      goToEntreeFormation(action, value, options = {}) {
        switch (action) {
          case types.ContributionsVEFActions.ACCESS:
            this.goToRecapEntreeFormation(value?.demandeApprenant.reference, options);
            break;
        }
      },

      /**
       * Get previous state default value
       *
       * @returns {string}
       */
      getDefaultPreviousState() {
        return 'app.connected.dashboard.accueil';
      },

      /**
       * Go to previous state of the router
       * If the router does not have a previous state, return to 'dashboard'
       */
      goToPreviousRouterState() {
        const defaultState = this.getDefaultPreviousState();

        if ($rootScope.previousStates.length > 0) {
          const previousState = $rootScope.previousStates.at(-1);

          $state.go(previousState.state, previousState.params);
        } else {
          $state.go(defaultState);
        }
      },
    };
  },
]);
