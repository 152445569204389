'use strict';
angular.module('portailDepotDemandeAide.depot').controller('domiciliationBancaireConfiguration', [
  '$rootScope',
  '$scope',
  '$q',
  '$modal',
  '$timeout',
  'aidesService',
  'alertsService',
  'cmisService',
  'contributionsService',
  'configuration',
  'userSessionService',
  'StoreService',
  '$log',
  function (
    $rootScope,
    $scope,
    $q,
    $modal,
    $timeout,
    aidesService,
    alertsService,
    cmisService,
    contributionsService,
    configuration,
    userSessionService,
    StoreService,
    $log
  ) {
    'use strict';
    // Domiciliation defined by user
    let newDomiciliation;
    $scope.deferredNext = false;
    $scope.pieceUploadInProgress = false;

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.noform = false;
    $scope.navigate.ns = $scope.domiciliationBancaireConfiguration.ns;
    $scope.domiciliationBancaireConfiguration.showErrors = $scope.showErrorsOnNavigate();

    const isTierslinkedUser = function () {
      const user = userSessionService.getUser();
      const linkedUsersHref = $scope.aide.demandeur?.expand?.linkedUsers?.map(({ href }) => href) ?? [];
      return linkedUsersHref.includes(user.self);
    };

    $scope.hideDocument = $scope.isAccessedThroughSharing && !isTierslinkedUser();

    $scope.navigate.next = (forget) => {
      if ($scope.pendingNext) {
        return;
      }
      $scope.deferredNext = false;
      if ($scope.pieceUploadInProgress) {
        $scope.deferredNext = true;
        return;
      }
      $scope.pendingNext = true;

      /**
       * Do not do anything about domiciliation bancaire if the page is accessed through sharing or if the user is not linked to the tiers
       */
      if ($scope.isAccessedThroughSharing && !isTierslinkedUser()) {
        $scope.goToStep('pieces', forget);
        return;
      }

      // Promises to handle before going to the next page
      const beforeGoToStepPromises = [];

      // The user is not creating a new domiciliation bancaire
      if (!$scope.createDom && $scope.selectedDom >= 0) {
        let documents = [];

        $scope.aide.domiciliationBancaire?.pieces?.forEach((piece) => {
          if (piece?.documents) documents = documents.concat(piece.documents);
        });

        const copyDocumentsPromises = documents.map((documentPiece) => {
          return aidesService.copyDocumentDomiciliationBancaireTiers(
            $scope.urlDocuments,
            documentPiece,
            'aide',
            $scope.aide
          );
        });

        beforeGoToStepPromises.push(...copyDocumentsPromises);
      }
      // The user created a new domiciliation but had selection displayed
      else if ($scope.showDomiciliations) {
        // Search for a correlation in tier's domiciliations
        const compareTo = $scope.aide.domiciliationBancaire.horsSEPA ? 'horsSEPA[0].valeur' : 'IBAN.numero';
        const correlationFound = _.findIndex(
          _.filter($scope.beneficiaire.domiciliationsBancaires, { actif: true }),
          (domLink) => _.get(domLink, compareTo) === _.get($scope.aide.domiciliationBancaire, compareTo)
        );

        // If a correlation is found, alert user that tiers' already has this domiciliation
        if (correlationFound > -1) {
          // displays confirmation Modal
          const scopeModal = $scope.$new();

          scopeModal.namespace = $scope.navigate.ns;
          // On validation, select the tiers' domiciliation
          scopeModal.validate = (modal) => modal.$hide();

          $modal({
            scope: scopeModal,
            template: 'depot/simple/domiciliation-bancaire/modal/correlation-confirm.html',
          });

          // Display the modal without going to next page
          return;
        }
      }

      // Go to next page
      $q.all(beforeGoToStepPromises)
        .catch((error) => {
          $timeout(() => {
            $rootScope.$broadcast(
              'alerts',
              alertsService.getAlertError('connected.depot.errors.missingDomiciliationBancaire', {
                xRequestId: error.config.headers['X-Request-ID'],
              }),

              'general-error-alert'
            );
          }, 0);
        })
        .finally(() => {
          $scope.pendingNext = false;
          $scope.goToStep('pieces', forget);
        });
    };

    // Page Options
    $scope.pageOptions = $scope.teleserviceConfiguration?.workflow?.pageDomiciliationBancaire ?? {};
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    }

    $scope.onBeforeUploadPiece = function (updatingDescription = false) {
      $scope.pieceUploadInProgress = true;
      if (!updatingDescription) {
        $scope.navigate.lockNavigation();
        // invalid properties are added on pieces/documents during upload, we don't want to save them
        $scope.navigate.lockSave();
      }
    };

    $scope.onAfterUploadPiece = function (updatingDescription = false, success = false) {
      $scope.pieceUploadInProgress = false;
      if (!updatingDescription) {
        $scope.navigate.unlockNavigation();
        $scope.navigate.unlockSave();
      }
      if ($scope.deferredNext) {
        $scope.deferredNext = false;
        if (success) {
          $scope.navigate.next();
        }
      }
    };

    // Persistence - Save the persistence when we add a new document on domiciliation piece or update/delete a document
    $scope.savePersistence = function () {
      if ($scope.contribution) {
        contributionsService.saveContribution($scope.aide, $scope.contribution);
      } else {
        aidesService.update($scope.aide);
      }
    };

    $scope.createDomiciliation = () => {
      // can't create a domiciliation if read only
      if ($scope.isDemandeurReadOnly) return;

      $scope.aide.domiciliationBancaire = newDomiciliation;
      $scope.selectedDom = -1;
      $scope.createDom = true;
    };

    /**
     * Select domiciliation from existing one
     *
     * @param  {number} index Index of domiciliations array
     */
    $scope.selectDomiciliation = (index) => {
      // If we leave the creation form, we keep the data somewhere
      if ($scope.selectedDom === -1 && $scope.aide.domiciliationBancaire && !$scope.aide.domiciliationBancaire.id) {
        newDomiciliation = $scope.aide.domiciliationBancaire;
      }
      $scope.aide.domiciliationBancaire = $scope.beneficiaire.domiciliationsBancaires[index];
      $scope.selectedDom = index;
      $scope.createDom = false;
    };

    /**
     * Initialize controller
     *
     * @returns {void}
     */
    function initialize() {
      // Validation Banque de France
      $scope.bdfFile = configuration?.domiciliationBancaire?.banqueDeFrance;
      $scope.validationIbanFr = $scope.bdfFile && configuration?.['demande-paiement']?.validationIbanFr;

      /**
       * If this page is read only because of sharing mode, we don't want to block the user.
       * Disable the "completude" for this page. In any case, "completude" will be checked at the end of the workflow.
       *
       * ? This overrides the default behavior from "createConfiguration" function in "depot-simple-controller"
       */

      if ($scope.isBeneficiaireReadOnly) {
        $scope.domiciliationBancaireConfiguration.remoteValidation = true;
      }

      // Add persistenceConfiguration to allowedExtensions
      $scope.persistenceConfiguration = _.get($scope.teleserviceConfiguration, 'persistenceConfiguration.expand', {});

      $scope.kind = $scope.contribution ? 'contribution' : 'aide';
      $scope.createDom = true;
      $scope.selectedDom = -1;

      // Principale domiciliation has a status that show a label
      $scope.principaleStatus = { label: $scope.navigate.ns + '.list.item.principal', class: 'success' };

      // Url documents
      $scope.urlDocuments = $scope.aide._links['mgs:documents'].href;

      // Tri des domiciliations : en premier la principale et ensuite on tri par titulaire
      _.get($scope, 'beneficiaire.domiciliationsBancaires', []).sort((a, b) => {
        if (a.principale && !b.principale) {
          return -1;
        }
        if (b.principale && !a.principale) {
          return 1;
        }
        return a.titulaire.localeCompare(b.titulaire);
      });

      // Display active domiciliations from tiers
      if (_.get($scope.beneficiaire, 'domiciliationsBancaires')) {
        const domiciliationBancairesActives = _.filter($scope.beneficiaire.domiciliationsBancaires, (dom) => {
          return dom.actif;
        });
        // If user's tiers has domiciliations, the user can pick one of them
        $scope.showDomiciliations = domiciliationBancairesActives.length > 0;
      }

      // If there are domiciliations, we search the selected one
      if ($scope.showDomiciliations) {
        let indexSelectedDb;
        let existingAideDomiciliationConformeIndex;

        if ($scope.aide.domiciliationBancaire) {
          existingAideDomiciliationConformeIndex = _.findIndex(
            $scope.beneficiaire.domiciliationsBancaires,
            (domLink) => domLink.id === _.get($scope, 'aide.domiciliationBancaire.id', '')
          );
        } else {
          // Par défaut, on sélectionne la domiciliation principale si elle active
          indexSelectedDb = _.findIndex(
            $scope.beneficiaire.domiciliationsBancaires,
            (domLink) => domLink.principale && domLink.actif
          );

          // Sinon on sélectionne la première
          if (indexSelectedDb < 0) {
            indexSelectedDb = _.findIndex($scope.beneficiaire.domiciliationsBancaires, (domLink) => domLink.actif);
          }
        }

        const selectedIndex = $scope.aide.domiciliationBancaire
          ? existingAideDomiciliationConformeIndex
          : indexSelectedDb;

        // The first time the page is loaded, the first domiciliation (0) is selected
        if (selectedIndex > -1) {
          $scope.selectDomiciliation(selectedIndex);
        }
      }
    }

    this.$onInit = () => {
      // Search for beneficiaire
      // For now we retrieve an empty object as we create beneficiaire every time
      $scope.beneficiaire = $scope.aide.demandeurEtBeneficiaire === false ? {} : StoreService.demandeur.get();

      $q.all(
        // expand domiciliations-bancaires documents
        $scope.beneficiaire?.domiciliationsBancaires?.flatMap((domiciliationBancaire) =>
          cmisService.expandAllPiecesDocuments(domiciliationBancaire.pieces, true)
        )
      )
        .then(() => initialize())
        .catch((error) => {
          $log.error('(depot-simple-domicilliation-bancaire-controller) $onInit error', error);
        });
    };
  },
]);
