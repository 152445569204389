'use strict';
informationsComplementairesDonneesExercice.$inject = ['$log', 'StoreService'];
/**
 * @module tiers
 * @name informationsComplementairesDonneesExercice
 * @description
 *
 * Controller "Données par exercice"
 * @param {object} mdmTypeDonneesExercice - Objet utilitaire permettant d'accéder aux données du mdm dans les directives
 * @param {object} tiersData - Objet dans le tiers ou seront stocké les informations saisies dans la directives
 * @param {object} tiersFamille - Famille du tiers pour restriction d'affichage sur les types de données par exercice à afficher
 * @param {string} exerciceCourant - Utilisé pour calculé les exercices affichés (N-1, N-2 etc.)
 * @param {string} [exerciceReference] - Exercice de référence permettant de savoir si cela à un sens d'afficher une colone.
 * Ex: exerciceCourant 2014 et exerciceRéférence 2012, dans le cas la valeur N-3 n'aurait pas de sens à être affichée
 * @param {Array} exerciceOffsets - Liste des exercices à afficher (-1,-2,-3 ...)
 * @param {Array} typeInfos - Liste des infos à afficher (et à chercher dans le MDM)
 * @param {boolean} [isRequired] - La saisie est-elle obligatoire ?
 * @param {string} visibilityStatusCallback - Etat d'affichage de la directive 'show' - 'hidden' - 'warning'
 */
angular
  .module('tiers')
  .directive('informationsComplementairesDonneesExercice', informationsComplementairesDonneesExercice);

informationsComplementairesDonneesExercice.$inject = ['$log', 'StoreService'];

/**
 *
 * @param {object} $log
 * @param {object} StoreService
 * @returns {object}
 */
function informationsComplementairesDonneesExercice($log, StoreService) {
  return {
    replace: true,
    transclude: true,
    templateUrl:
      'tiers/tiers-directives/informations-complementaires-donnees-exercice/informations-complementaires-donnees-exercice.html',
    scope: {
      mdmTypeDonneesExercice: '=',
      tiersData: '=',
      tiersDataPath: '<',
      tiersFamille: '=',
      exerciceCourant: '=',
      exerciceReference: '=',
      exerciceOffsets: '=',
      typeInfos: '=',
      isRequired: '=',
      visibilityStatusCallback: '=',
      viewConfiguration: '=',
      readOnly: '<',
    },

    link: (scope) => {
      const oldData = _.cloneDeep(scope.tiersData);

      // All the fields are required ?
      scope.config = {
        required: scope.isRequired,
      };

      // fail fast
      if (!scope.exerciceCourant || !scope.exerciceOffsets || scope.exerciceOffsets.length === 0) {
        scope.visibilityStatusCallback = 'hidden';
        scope.config.required = false;
        $log.error('informations-complementaires-donnees-exercice missing important param for the directive');
        return;
      }

      scope.$watch('isRequired', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          scope.config.required = newValue;
        }
      });

      /*
       * When the model binded in the table is updated,
       * we update the tiers object with the right structure of the entity
       */
      scope.valuesDonneesExercice = {};
      // we use a debounce to avoid overload
      const watchValuesDonneesExercice = _.debounce(function (newValue) {
        const removedValues = [];

        if (newValue && !_.isEmpty(newValue)) {
          let newTiersDonneesExercice = [];
          for (let exercice in scope.valuesDonneesExercice) {
            if (Object.hasOwn(scope.valuesDonneesExercice, exercice)) {
              for (let eff in scope.valuesDonneesExercice[exercice]) {
                // Registering only filled entry (not null or undefined)
                const entry = _.get(scope.valuesDonneesExercice, `${exercice}.${eff}`);
                if (_.isNumber(entry)) {
                  newTiersDonneesExercice.push({
                    exercice: parseInt(exercice),
                    cle: eff,
                    valeur: entry,
                    libelle: _.get(scope.mdmTypeDonneesExercice, `object.${eff}.expand.libelle.value`),
                  });
                } else {
                  removedValues.push(entry);
                }
              }
            }
          }

          // We add the not displayed properties if needed
          _.forEach(scope.tiersData && scope.tiersData.item, (item) => {
            const dataModified = _.find(newTiersDonneesExercice, { exercice: item.exercice, cle: item.cle });
            // if my cle/exercice has been modified, i have nothing to do
            if (!dataModified) {
              // if its doesnt exists i must verify if it was deleted
              const itemDeleted = !_.some(newTiersDonneesExercice, { exercice: item.exercice, cle: item.cle });

              if (!itemDeleted) {
                // if the item hasn't been deleted, i must add the hidden (old) one
                newTiersDonneesExercice.push(item);
              }
            }
          });

          newTiersDonneesExercice = _.sortBy(newTiersDonneesExercice, ['exercice', 'cle']);

          scope.tiersData = {
            item: newTiersDonneesExercice,
          };

          // if data changed and the screen is not readOnly we compute patches
          if (!_.isEqual(oldData, newTiersDonneesExercice) && !scope.readOnly) {
            StoreService.tiers.patches.add('add', scope.tiersDataPath, scope.tiersData);
          }
        }
      }, 250);

      scope.$watch('valuesDonneesExercice', watchValuesDonneesExercice, true);

      scope.typesDonneesExercice = [];
      scope.exercicesDonneesExercice = [];
      const initEmptyValuesFromTeleprocedure = () => {
        scope.exercicesDonneesExercice = [];

        // What is the configuration for the current typeTiers ?
        scope.typesDonneesExercice = [];
        _.forEach(scope.typeInfos, (reference) => {
          const typeDonneesExercice = _.find(scope.mdmTypeDonneesExercice.array, (type) => {
            return _.includes(type.href, reference);
          });
          if (typeDonneesExercice) {
            scope.typesDonneesExercice.push({
              reference: typeDonneesExercice.expand.reference,
              title: typeDonneesExercice.expand.libelle.value,
            });
          }
        });

        const emptyValuesDonneesExercice = {};

        // Exercice list
        for (let index in scope.exerciceOffsets) {
          if (Object.hasOwn(scope.exerciceOffsets, index)) {
            const exercice = scope.exerciceOffsets[index] + scope.exerciceCourant;

            // Si on utilise l'exercice de référence, alors il faut que l'exercice que l'on
            // souhaite afficher soit plus ancien que l'exercice de référence, sinon cela n'a pas de sens
            // ex : demander l'effectif 2010 d'une entreprise créée en 2011
            if (!scope.exerciceReference || (scope.exerciceReference && exercice < scope.exerciceReference)) {
              continue;
            }

            scope.exercicesDonneesExercice.push(exercice);

            emptyValuesDonneesExercice[exercice] = {};

            // For the moment, do not init at 0 ... let the field unfilled
            for (let i in scope.typesDonneesExercice) {
              if (Object.hasOwn(scope.typesDonneesExercice, i)) {
                emptyValuesDonneesExercice[exercice][scope.typesDonneesExercice[i].reference] = null;
              }
            }
          }
        }
        scope.valuesDonneesExercice = emptyValuesDonneesExercice;
      };

      const initValuesFromTiers = (values) => {
        const item = _.get(values, 'item');

        if (!item) {
          return;
        }

        _.forEach(item, (value) => {
          if (scope.valuesDonneesExercice[value.exercice]) {
            scope.valuesDonneesExercice[value.exercice][value.cle] = value.valeur;
          }
        });
      };

      // Quand on change l'exercice de référence, il faut de nouveau
      // calculer les exercices visibles
      scope.$watch('exerciceReference', () => {
        initEmptyValuesFromTeleprocedure();
        initValuesFromTiers(scope.tiersData);

        if (!scope.exerciceReference || (scope.exercicesDonneesExercice.length === 0 && scope.exerciceReference)) {
          scope.visibilityStatusCallback = 'warning';
          scope.config.required = false;
        } else {
          scope.visibilityStatusCallback = 'show';
          scope.config.required = scope.isRequired;
        }
      });
    },
  };
}
