'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandesComplementPiecesController', [
  '$scope',
  '$rootScope',
  '$modal',
  '$log',
  'aide',
  'teleservice',
  'aidesService',
  'complementsService',
  'demandesComplementPiecesService',
  'alertsService',
  'aideComplementsConfiguration',
  'Piece',
  '$state',
  'publicSettingsFinancement',
  'tiersService',
  '$stateParams',
  'previousState',
  'piecesService',
  '$q',
  'cmisService',
  function (
    $scope,
    $rootScope,
    $modal,
    $log,
    aide,
    teleservice,
    aidesService,
    complementsService,
    demandesComplementPiecesService,
    alertsService,
    aideComplementsConfiguration,
    Piece,
    $state,
    publicSettingsFinancement,
    tiersService,
    $stateParams,
    previousState,
    piecesService,
    $q,
    cmisService
  ) {
    'use strict';

    // set setting parameter for 1 document by piece
    $scope.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

    // Disable button when a document is uploading
    $scope.isUploadInProgress = false;

    $scope.hasBeenValidated = false;

    $scope.goBack = function () {
      switch (_.get(previousState, 'name')) {
        case 'app.connected.dashboard.recapitulatif':
        case 'app.connected.dashboard.sollicitations':
          $state.go(previousState.name, previousState.params);
          break;
        default:
          //We reload the parent state to refresh the aides list and its demandes complements
          $state.go(
            'app.connected.dashboard.aides.demandesAides',
            { page: $stateParams.page },
            { reload: 'app.connected.dashboard.aides' }
          );
      }
    };

    // Acquiring complements
    $scope.complements = _.map(aide.demandesComplementPieces, 'expand');

    $scope.updateAide = function (patches) {
      aidesService.patchAide(aide, patches);
    };

    const teleserviceWorkflow = teleservice.workflow[teleservice.workflow.type];

    /**
     * Prepares the persistence to fill the complement request with new files
     *
     * @param  {object} complement complement
     * @returns {void}
     */
    $scope.fillComplement = function (complement) {
      return demandesComplementPiecesService.getWithDocuments(complement.id).then((complement) => {
        updateDemandeComplementPiecesConfig(complement);
        $scope.openModalComplement(complement);
      });
    };

    /**
     * Update the config of the pieces on the demande complement pieces
     * (get config from the piece on the aide and on the teleservice)
     *
     * @param {object} demandeComplementPieces demande complement pieces
     * @returns {void}
     */
    function updateDemandeComplementPiecesConfig(demandeComplementPieces) {
      for (let i = 0; i < demandeComplementPieces.pieces.length; i++) {
        const piece = demandeComplementPieces.pieces[i];

        const aidePiece = aide.pieces.find((e) => e.reference === piece.reference && e.fonction === 'depot');
        const pieceConf = teleserviceWorkflow.pagePieces.modelesPieces.find(
          (e) => e.reference === piece.reference && e.fonction === 'depot'
        );

        const pieceWithConfig = _.merge({}, aidePiece, pieceConf);

        if (piece.documents) {
          pieceWithConfig.documents = piece.documents;
        }

        demandeComplementPieces.pieces[i] = new Piece(pieceWithConfig);
      }
    }

    // Disable the validate button when a documents is uploading
    $scope.disableValidateButton = function (disable) {
      $scope.isUploadInProgress = disable;
    };

    /**
     * Open the modal of piece-form to fill the demande complement pieces
     *
     * @param {object} demandeComplementPieces demande complement pieces
     */
    $scope.openModalComplement = function (demandeComplementPieces) {
      // Disable button to prevent double clic
      $scope.hasBeenValidated = false;

      const scopeModal = $scope.$new();

      // Configuration and functions
      scopeModal.aide = aide;
      scopeModal.complement = demandeComplementPieces;
      scopeModal.complementsConfiguration = angular.copy(aideComplementsConfiguration);
      scopeModal.complementsConfiguration.ns = 'connected.dashboard.aides.complements.fill-modal';
      scopeModal.disableValidateButton = $scope.disableValidateButton;

      // The size limit has to be converted into Mb for the directive
      scopeModal.complementsConfiguration.maxDocumentSize =
        scopeModal.complementsConfiguration.maxDocumentSize / 1024 / 1024;
      scopeModal.complementsConfiguration.allowedExtensions = scopeModal.complementsConfiguration.allowedExtension;
      scopeModal.persistenceConfiguration = _.get(
        teleservice,
        'persistenceConfiguration.expand',
        scopeModal.complementsConfiguration
      );

      scopeModal.mdm = $scope.mdm;
      scopeModal.updatePiece = $scope.updatePiece;
      scopeModal.tiers = _.get(aide, 'beneficiaires[0].expand');
      scopeModal.sendComplements = $scope.sendComplements;
      scopeModal.onDocumentUploaded = $scope.onDocumentUploaded;

      scopeModal.updateAide = $scope.updateAide;

      // Url where to upload files
      scopeModal.urlDocuments = aide._links['mgs:documents'].href;

      // Directory of file's icons
      scopeModal.urlFileIcons = './resources/images/file-icons/';

      // set setting parameter for 1 document by piece
      scopeModal.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

      scopeModal.unDocumentParPieceAlert = [];
      if (scopeModal.unDocumentParPiece) {
        // Display alert if Activated
        scopeModal.unDocumentParPieceAlert.push(
          alertsService.getAlertWarning('aides.depotpieces.informationsLimitaionDocument.content')[0]
        );
      }

      // Opens the modal
      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-complement-pieces/modal/modal-complements.html',
        backdrop: 'static',
      });
    };

    $scope.hrefDocumentsAdded = [];
    $scope.onDocumentUploaded = function (doc) {
      if (doc) $scope.hrefDocumentsAdded.push(doc.href);
    };

    /**
     * On the update of a demande complement pieces
     *
     * @param {object} demandeComplementPieces
     */
    $scope.updatePiece = function (demandeComplementPieces) {
      demandesComplementPiecesService.update(demandeComplementPieces);
    };

    /**
     * clone and save piece in the tiers if the piece exist in the 'famille'
     *
     * @returns {Promise | void}
     */
    function copyPiecesIntoTiers() {
      if (_.isEmpty($scope.hrefDocumentsAdded)) {
        return;
      }
      return tiersService
        .getCurrentTiers({}, 'famille')
        .then((tiers) => piecesService.copyAidePiecesInTiersPieces(aide, tiers, $scope.hrefDocumentsAdded))
        .then((tiers) => {
          $scope.hrefDocumentsAdded = [];
          return tiersService.saveTiers(tiers);
        });
    }

    /**
     * Copy the new files into aide
     *
     * @param {object[]} contentPieces
     */
    function copyPersistencePieceInAide(contentPieces) {
      contentPieces.forEach((piece) => {
        if (piece.documents && piece.documents.length > 0) {
          aide.pieces.forEach((TSpiece) => {
            if (TSpiece.reference === piece.reference) {
              TSpiece.documents = piece.documents;
            }
          });
        }
      });
    }
    /**
     * Validate the selected files
     *
     * @param {object} demandeComplementPieces demande complement pieces
     * @param {object} modal modal instance
     */
    $scope.sendComplements = function (demandeComplementPieces, modal) {
      $scope.hasBeenValidated = true;

      demandeComplementPieces.caracteristiques = demandeComplementPieces.caracteristiques || {};

      complementsService
        .putAvancement(demandeComplementPieces, 'REPONSE_DEMANDEUR')
        .then(function (updatedComplement) {
          _.remove($scope.complements, {
            id: demandeComplementPieces.id,
          });

          $scope.complements.push(updatedComplement);
        })
        .then(function () {
          copyPersistencePieceInAide(demandeComplementPieces.pieces);

          return copyPiecesIntoTiers();
        })
        .then(function () {
          modal.$hide();
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertSuccess('connected.dashboard.aides.complements.validation-success'),
            'complements-success'
          );
          $rootScope.$broadcast('refresh-menu');
        })
        .catch(function (e) {
          $log.error('[dashboardDemandesComplementPiecesController] sendComplements', e);
          modal.$broadcast(
            'alerts',
            alertsService.getAlertError('connected.dashboard.aides.complements.fill-modal.error.unknow'),
            'complements-error'
          );
        });
    };

    /**
     * Méthode d'affichage de la date de la demande en fonction du statut
     *
     * @param {object} demandeComplement
     * @returns {string}
     */
    $scope.getDateAffichage = function (demandeComplement) {
      var avancement = _.get(demandeComplement, 'avancement');
      var dateReponse = _.get(demandeComplement, 'dateReponse');
      var dateCloture = _.get(demandeComplement, 'dateCloture');
      var dateDemande = _.get(demandeComplement, 'dateDemande');

      return avancement === 'REPONSE_DEMANDEUR' ? dateReponse : avancement === 'CLOTURE' ? dateCloture : dateDemande;
    };

    this.$onInit = () => {
      const tiersPieces = aide?.beneficiaires?.[0]?.expand?.pieces;
      if (!Array.isArray(tiersPieces)) {
        return $q.resolve();
      }
      return $q
        .all(
          // expand documents (porte-documents needs them)
          cmisService.expandAllPiecesDocuments(tiersPieces)
        )
        .catch((error) => {
          $log.error('(demande-complement-pieces-controller) $onInit error', error);
        });
    };
  },
]);
