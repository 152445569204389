'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurBeneficiaireController', [
  '$rootScope',
  '$scope',
  'StoreService',
  'alertsService',
  '$translate',
  'aidesService',
  function ($rootScope, $scope, StoreService, alertsService, $translate, aidesService) {
    'use strict';

    /**
     * Set demandeurEtBeneficiaire on aide if valid
     *
     * @param {boolean} displayError display error
     * @returns {boolean} true if valid
     */
    function setDemandeurEtBeneficiaireIfValid(displayError = false) {
      if ($scope.tmp.demandeurIsBeneficiaire) {
        const isFamilleAllowed = aidesService.isBeneficiaireFamilyAuthorized(
          $scope.aide.demandeur?.expand?.famille?.href,
          $scope.teleserviceConfiguration
        );

        if (!isFamilleAllowed) {
          if (displayError) {
            $rootScope.$broadcast(
              'alerts',
              alertsService.getAlertError($translate.instant('teleservice.errors.beneficiaireWrongFamille'))
            );
          }

          // Do not change step if famille is not in allowed list for beneficiaire
          return false;
        }
      }

      // Apply the response on aide only if the choice is valid to prevent errors
      // The aide cannot be saved if the demandeur is set as beneficiaire and its
      // famille is not authorized
      $scope.aide.demandeurEtBeneficiaire = $scope.tmp.demandeurIsBeneficiaire;
      return true;
    }

    $scope.cleanNavigate();
    $scope.navigate.ns = 'teleservice.demandeur-beneficiaire';
    $scope.navigate.next = function (forget) {
      const hasBeenSet = setDemandeurEtBeneficiaireIfValid(true);
      if (!hasBeenSet) return;

      if ($scope.aide.demandeurEtBeneficiaire) {
        $scope.aide.beneficiaires.splice(0, 1);
        StoreService.beneficiaire.set(null);
        $scope.goToStep('informations-generales', forget);
      } else {
        $scope.goToStep('beneficiaire-famille', forget);
      }
    };

    // Skip ?
    if (
      $scope.teleserviceConfiguration.workflow.depotDelegue.actif &&
      ((!_.isUndefined($scope.teleserviceConfiguration.workflow.depotDelegue.delegationObligatoire) &&
        $scope.teleserviceConfiguration.workflow.depotDelegue.delegationObligatoire) ||
        (_.isUndefined($scope.teleserviceConfiguration.workflow.depotDelegue.delegationObligatoire) &&
          !_.isUndefined($scope.teleserviceConfiguration.workflow.depotDelegue.demandeurPeutEtreBeneficiaire) &&
          $scope.teleserviceConfiguration.workflow.depotDelegue.demandeurPeutEtreBeneficiaire))
    ) {
      $scope.goToStep('beneficiaire-famille', true);
    }

    if (!$scope.teleserviceConfiguration.workflow.depotDelegue.actif) {
      $scope.goToStep('informations-generales', true);
    }

    $scope.viewConfiguration = $scope.demandeurBeneficiaireConfiguration;
    $scope.viewConfiguration.ns = 'teleservice.demandeur-beneficiaire';

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';

    // Stored in a separate variable. It will be applied on aide only when navigating to next page
    // if the demandeur famille is allowed as beneficiaire. Otherwise the aide cannot be saved.
    $scope.tmp = { demandeurIsBeneficiaire: $scope.aide.demandeurEtBeneficiaire };

    /**
     * In depot delegue if no value is selected depot must be blocked
     */
    function blockDepotIfNoValueIsSelected() {
      $scope.navigate.block =
        $scope.tmp.demandeurIsBeneficiaire === null || $scope.tmp.demandeurIsBeneficiaire === undefined;
    }

    blockDepotIfNoValueIsSelected();

    /**
     * Update the blocking aspect on depot when value changes
     */
    $scope.demandeurIsBeneficiaireChanged = () => {
      blockDepotIfNoValueIsSelected();
      setDemandeurEtBeneficiaireIfValid();
    };
  },
]);
