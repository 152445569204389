angular.module('common.filters').filter('sanitize', [
  'sanitizerService',
  function (sanitizerService) {
    return function (input, options) {
      if (!input) return input;
      if (typeof input.$$unwrapTrustedValue === 'function') return input; // already sanitized
      return sanitizerService.sanitize(input, options);
    };
  },
]);
