'use strict';
angular.module('portailDepotDemandeAide.dashboard').directive('recapitulatifAvisPieces', [
  'piecesService',
  function (piecesService) {
    'use strict';

    return {
      replace: true,
      templateUrl: 'dashboard/contributions/contributions-recapitulatif/pieces/recapitulatif-pieces.html',
      scope: {
        contribution: '=',
        piecesConfiguration: '=',
      },

      link: function (scope) {
        scope.viewConfiguration = scope.piecesConfiguration;

        scope.urlFileIcons = './resources/images/file-icons/';

        // When we get contribution, the expand property is directly in root
        // Avoiding changing in piece-form because it is used everywhere
        scope.contribution.avis?.pieces?.forEach((piece) => {
          return piece.documents?.forEach((doc) => {
            doc.expand = {
              properties: doc?.expand?.properties ?? doc.properties,
              propertiesExtension: doc?.expand?.propertiesExtension ?? doc.propertiesExtension,
            };
            delete doc.properties;
            delete doc.propertiesExtension;
            return doc;
          });
        });
        /**
         * Evaluate conditionAffichage (visible/hidden) setting on a piece
         *
         * @function isPieceVisible
         * @param {object} piece the piece
         * @returns {boolean} the evaluate condition
         */
        scope.isPieceVisible = function (piece) {
          return piecesService.evalConditionPiece(scope, piece, 'conditionAffichage');
        };
      },
    };
  },
]);
