class DepotSimpleInformationsGeneralesSectionAvisSituation {
  constructor($scope, $timeout, licenseService, aidesService, contributionsService, expressionsService) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.licenseService = licenseService;
    this.aidesService = aidesService;
    this.contributionsService = contributionsService;
    this.expressionsService = expressionsService;

    this.sectionConfiguration = {};
    this.fields = {};
    this.state = {
      isSectionActive: false,
      dataFilled: false,
      dataNotFound: false,
      refreshFailed: false,
      numeroAllocataire: '',
      numeroAllocataireValid: false,
      codePostal: '',
      codePostalValid: false,
      data: undefined,
      mustFetchWarningDisplayed: false,
      currentlyFetching: false,
    };
  }

  $onInit() {
    this.initComponentState();

    this.$scope.$on('formSubmitted', () => {
      if (!this.state.dataNotFound && !this.state.dataFilled) {
        this.updateState({ mustFetchWarningDisplayed: true });
      }
    });
  }

  /**
   * Returns if the section should be displayed
   *
   * @returns {boolean} true if the section should be displayed
   */
  isDisplayed() {
    const isActive = this.state.isSectionActive;
    const conditionMasquage = this.sectionConfiguration.conditionMasquage;
    this.displayed =
      isActive && (!conditionMasquage || !this.expressionsService.evaluateExpression(conditionMasquage, this.aide));
    return this.displayed;
  }

  /**
   * Refresh the avis situation data on demande
   *
   * @returns {Promise<void> | void}
   */
  refreshAvisSituation() {
    const searchParamsValid =
      this.state.dataFilled || (this.state.numeroAllocataireValid && this.state.codePostalValid);

    if (!searchParamsValid) {
      return;
    }

    this.updateState({ currentlyFetching: true });

    const updatePromise = this.contribution
      ? this.contributionsService.updateAvisSituation(this.contribution.reference, {
          numeroAllocataire: this.state.numeroAllocataire,
          codePostal: this.state.codePostal,
        })
      : this.aidesService.updateAvisSituation(this.aide.reference, {
          numeroAllocataire: this.state.numeroAllocataire,
          codePostal: this.state.codePostal,
        });

    return (
      updatePromise
        .then((result) => {
          this.updateState({
            dataNotFound: result.noDataFound,
            refreshFailed: result.refreshFailed,
            dataFilled: !result.noDataFound,
            mustFetchWarningDisplayed: false,
          });

          this.setAvisSituationValue(result.demande.externalData?.apiParticulier?.compositionFamiliale);
        })
        // Fetch the aide to avoid concurrent udate problem
        // (aide was updated on the server)
        .then(() => this.aidesService.getAide(this.aide.reference))
        .finally(() => this.updateState({ currentlyFetching: false }))
    );
  }

  /**
   * Remove the avis situation data and search params
   *
   * @returns {Promise<void>}
   */
  resetAvisSituation() {
    let updatePromise;

    if (this.contribution) {
      const cleanedAide = _.cloneDeep(this.aide);
      delete cleanedAide.externalData?.apiParticulier?.compositionFamiliale;
      updatePromise = this.contributionsService.saveContribution(cleanedAide, this.contribution);
    } else {
      updatePromise = this.aidesService.patchAide(this.aide, [
        { op: 'remove', path: '/externalData/apiParticulier/compositionFamiliale' },
      ]);
    }

    return updatePromise.then(() => {
      this.updateState({
        dataNotFound: false,
        refreshFailed: false,
        dataFilled: false,
        numeroAllocataire: '',
        codePostal: '',
        mustFetchWarningDisplayed: false,
      });
      this.setAvisSituationValue({});

      this.$timeout(() => {
        this.updateState({
          numeroAllocataireValid: false,
          codePostalValid: false,
        });
        this.$scope.$apply();
      });
    });
  }

  /**
   * Update avis situation data stored in-memory to match the updated aide
   *
   * @param {object} compositionFamilialeData composition familiale data
   * @returns {void}
   */
  setAvisSituationValue(compositionFamilialeData) {
    _.set(this.aide, 'externalData.apiParticulier.compositionFamiliale', compositionFamilialeData);

    if (this.contribution) {
      _.set(
        this.contribution,
        'valeursSaisies.externalData.apiParticulier.compositionFamiliale',
        compositionFamilialeData
      );
    }

    this.updateState({ data: compositionFamilialeData });
  }

  /**
   * Initialize the component state
   *
   * @returns {void}
   */
  initComponentState() {
    this.sectionConfiguration =
      _.get(this.teleservice, 'workflow.pageInformationsGenerales.avisSituation') ||
      _.get(this.teleservice, 'workflow.simple.pageInformationsGenerales.avisSituation');

    const hasValidLicense = this.licenseService.getPresenceLicence(this.licenseService.LICENSES.API_PARTICULIER);
    // license is not required in recapitulatif mode
    // (it cause the section not to be displayed otherwise)
    const mustHaveValidLicense = !this.isRecapitulatifMode;

    if (!this.sectionConfiguration?.actif || (mustHaveValidLicense && !hasValidLicense)) {
      this.sectionConfiguration = { actif: false };
      this.updateState({ isSectionActive: false });
      return;
    }

    for (const field of this.sectionConfiguration.fields) {
      this.fields[field.reference] = field;
    }

    const dataOnDemande = this.aide.externalData?.apiParticulier?.compositionFamiliale;

    this.updateState({
      isSectionActive: true,
      dataFilled: dataOnDemande?.numeroAllocataire && dataOnDemande?.codePostal,
      refreshFailed: dataOnDemande?.updateFailed,
      numeroAllocataire: dataOnDemande?.numeroAllocataire,
      codePostal: dataOnDemande?.codePostal,
      data: dataOnDemande,
    });
  }

  /**
   * Update the component state
   *
   * @param {object} newState state properties to update
   * @returns {void}
   */
  updateState(newState) {
    this.state = { ...this.state, ...newState };
    this.updateSectionValidity();
  }

  /**
   * Update the component state when search params change
   *
   * @returns {void}
   */
  onSearchParamsChange() {
    this.updateSectionValidity();
    this.resetErrors();
  }

  /**
   * Reset errors and warnings
   *
   * @returns {void}
   */
  resetErrors() {
    if (this.state.dataNotFound) {
      this.updateState({ dataNotFound: false, refreshFailed: false });
    } else {
      this.updateState({ mustFetchWarningDisplayed: false });
    }
  }

  /**
   * Update the section validity based on the component state
   *
   * @returns {void}
   */
  updateSectionValidity() {
    const hasSearchParamsFilled = !!this.state.numeroAllocataire && !!this.state.codePostal;
    this.valid = !this.displayed || !hasSearchParamsFilled || this.state.dataFilled;
  }

  /**
   * Format date stored on the demande avis situation for formatting using
   * the AngularJS "date" filter
   *
   * (from ddMMyyyy to yyyy-MM-dd)
   *
   * @param {string} date to format
   * @returns {string} formatted date
   */
  formatDateForFilter(date) {
    if (!date) {
      return '';
    }

    const day = date.slice(0, 2);
    const month = date.slice(2, 4);
    const year = date.slice(4, 8);
    return `${year}-${month}-${day}`;
  }
}

DepotSimpleInformationsGeneralesSectionAvisSituation.$inject = [
  '$scope',
  '$timeout',
  'licenseService',
  'aidesService',
  'contributionsService',
  'expressionsService',
];

angular.module('portailDepotDemandeAide.depot').component('depotSimpleInformationsGeneralesSectionAvisSituation', {
  controller: DepotSimpleInformationsGeneralesSectionAvisSituation,
  templateUrl: 'depot/simple/informations-generales/avis-situation-section/avis-situation-section.component.html',
  bindings: {
    teleservice: '<',
    aide: '<',
    contribution: '<',
    valid: '=?',
    // mode that hides everything that is not informations stored on the demande
    // (used to display aide recapitulatif without additional information)
    isRecapitulatifMode: '<',
    showErrors: '<',
  },
});
