angular.module('tiers').component('agrementsDemandeurs', {
  bindings: {
    name: '@',
    configuration: '<',
    readOnly: '<',
    tiers: '<',
    hideLegend: '<',
    updateTiers: '<',
  },

  templateUrl: 'tiers/tiers-directives/agrements-demandeurs/agrements-demandeurs.html',
  controller: agrementsDemandeursController,
});

agrementsDemandeursController.$inject = ['$q', '$scope', '$modal', 'tiersService'];

/**
 *
 * @param {object} $q
 * @param {object} $scope
 * @param {object} $modal
 * @param {object} tiersService
 */
function agrementsDemandeursController($q, $scope, $modal, tiersService) {
  var modalEditTemplate = 'tiers/tiers-directives/agrements-demandeurs/modal/modal-edit-agrement.html';
  var modalDeleteTemplate = 'tiers/tiers-directives/agrements-demandeurs/modal/modal-delete-agrement.html';
  var modalAddTemplate = 'tiers/tiers-directives/agrements-demandeurs/modal/modal-add-agrement.html';
  var vm = this;
  var thematiqueVide = {};

  vm.$onInit = function () {
    thematiqueVide = {
      active: true,
      reference: vm.tiers.reference,
      tiers: {
        href: vm.tiers.id,
        title: vm.tiers.title,
      },
    };

    $scope.viewConfiguration = _.merge(
      {
        ns: 'teleservice.demandeur-thematiques.TIERS_ASSOCIATION.agrements-demandeurs',
      },
      vm.configuration
    );

    vm.thematiqueAssociation = _.get(vm.tiers, 'thematiquesLiees.association', thematiqueVide);
  };

  /**
   *  Affichage d'une fenêtre modale pour la creation d'un agrément
   */
  vm.addAgrement = function () {
    var scopeModal = $scope.$new();
    scopeModal.agrement = {};
    scopeModal.niveauxAgrement = _.get(vm.tiers, 'famille.expand.niveauxAgrement');
    scopeModal.viewConfiguration = $scope.viewConfiguration;
    scopeModal.confirmAddAgrement = $scope.confirmAddAgrement;
    scopeModal.updateTiers = vm.updateTiers;
    scopeModal.isConfirmationState = false;
    scopeModal.onSaveClick = () => {
      scopeModal.isConfirmationState = true;
    };
    scopeModal.onCancelClick = () => {
      scopeModal.isConfirmationState = false;
    };

    $modal({
      scope: scopeModal,
      template: modalAddTemplate,
      backdrop: 'static',
    });
  };
  /**
   *  Affichage d'une fenêtre modale pour la modification d'un agrément
   *
   * @param  {index} index de l'agrément que l'on souhaite modifier
   */
  vm.editAgrement = function (index) {
    var scopeModal = $scope.$new();
    scopeModal.confirmEditAgrement = $scope.confirmEditAgrement;
    scopeModal.agrement = angular.copy(vm.thematiqueAssociation.agrements[index]);
    scopeModal.niveauxAgrement = _.get(vm.tiers, 'famille.expand.niveauxAgrement');
    scopeModal.index = index;
    scopeModal.viewConfiguration = $scope.viewConfiguration;
    scopeModal.updateTiers = vm.updateTiers;
    scopeModal.isConfirmationState = false;
    scopeModal.onSaveClick = () => {
      scopeModal.isConfirmationState = true;
    };
    scopeModal.onCancelClick = () => {
      scopeModal.isConfirmationState = false;
    };

    $modal({
      scope: scopeModal,
      template: modalEditTemplate,
      backdrop: 'static',
    });
  };

  /**
   *  Affichage d'une fenêtre modale demandant la confirmation de la suppression d'un agrément
   *
   * @param  {index} index de l'agrément que l'on souhaite supprimer
   */
  vm.deleteAgrement = function (index) {
    var scopeModal = $scope.$new();
    scopeModal.confirmDeleteAgrement = $scope.confirmDeleteAgrement;
    scopeModal.agrement = vm.thematiqueAssociation.agrements[index];
    scopeModal.index = index;
    $modal({
      scope: scopeModal,
      template: modalDeleteTemplate,
      backdrop: 'static',
    });
  };

  /**
   * Confirm add agrement
   *
   * @param {object} agrement agrement
   * @returns {Promise<void>}
   */
  $scope.confirmAddAgrement = function (agrement) {
    vm.thematiqueAssociation.agrements = vm.thematiqueAssociation.agrements || [];
    vm.thematiqueAssociation.agrements.push(agrement);

    return vm.updateTiers
      ? tiersService.updateAgrements(vm.tiers.reference, vm.thematiqueAssociation.agrements)
      : $q.resolve();
  };

  /**
   * Confirm edit agrement
   *
   * @param {object} agrement agrement
   * @param {number} index index
   * @returns {Promise<void>}
   */
  $scope.confirmEditAgrement = function (agrement, index) {
    vm.thematiqueAssociation.agrements[index] = agrement;

    return vm.updateTiers
      ? tiersService.updateAgrements(vm.tiers.reference, vm.thematiqueAssociation.agrements)
      : $q.resolve();
  };

  /**
   * Confirm delete agrement
   *
   * @param {number} index index
   * @returns {Promise<void>}
   */
  $scope.confirmDeleteAgrement = function (index) {
    vm.thematiqueAssociation.agrements.splice(index, 1);

    return vm.updateTiers
      ? tiersService.updateAgrements(vm.tiers.reference, vm.thematiqueAssociation.agrements)
      : $q.resolve();
  };
}
