class AgrementFormController {
  constructor($scope, $element, $translate, dateFormatterService) {
    this.$scope = $scope;
    this.$element = $element;
    this.$translate = $translate;
    this.dateFormatterService = dateFormatterService;
  }

  $onInit() {
    this.namespace = this.namespace || this.viewConfiguration.ns || 'agrement.form';
    this.datePickerConfig = {
      min: '1800-01-01',
    };

    // enDateDu should not have ISOString format
    if (this.agrement.enDateDu && !this.agrement.enDateDu.match(/^\d{4}-\d{2}-\d{2}$/)) {
      this.agrement.enDateDu = this.dateFormatterService.getDateOnly(new Date(this.agrement.enDateDu));
    }

    this.niveauxAgrement = (this.niveauxAgrement || []).map((agrement) => ({
      value: agrement.href,
      title: agrement.title,
    }));
    if (this.agrement.niveau) {
      this.agrementNiveau = this.agrement.niveau.href;
    }
  }

  /**
   * On type change
   *
   * @param {string} value value
   * @returns {void}
   */
  onTypeChange(value) {
    if (value) {
      this.agrement.type = value;
    } else {
      delete this.agrement.type;
    }
  }

  /**
   * On numero change
   *
   * @param {string} value value
   * @returns {void}
   */
  onNumeroChange(value) {
    if (value) {
      this.agrement.numero = value;
    } else {
      delete this.agrement.numero;
    }
  }

  /**
   * On niveau change
   *
   * @param {string} value value
   * @returns {void}
   */
  onNiveauChange(value) {
    const matchingNiveauAgrement = this.niveauxAgrement?.find((agrement) => agrement.value === value);
    if (!matchingNiveauAgrement) {
      delete this.agrement.niveau;
    } else {
      this.agrement.niveau = {
        href: matchingNiveauAgrement.value,
        title: matchingNiveauAgrement.title,
      };
    }
  }

  /**
   * On attribuePar change
   *
   * @param {string} value value
   * @returns {void}
   */
  onAttribueParChange(value) {
    if (value) {
      this.agrement.attribuePar = value;
    } else {
      delete this.agrement.attribuePar;
    }
  }

  /**
   * On enDateDu change
   *
   * @param {string} value value
   * @returns {void}
   */
  onEnDateDuChange(value) {
    if (value) {
      this.agrement.enDateDu = value;
    } else {
      delete this.agrement.enDateDu;
    }
  }

  /**
   * Set form validity
   *
   * @param {boolean} isValid
   * @returns {void}
   */
  onFormValid(isValid) {
    this.viewConfiguration.isFormValid = isValid;
  }
}

AgrementFormController.$inject = ['$scope', '$element', '$translate', 'dateFormatterService'];

angular.module('tiers').component('agrementForm', {
  bindings: {
    agrement: '=',
    namespace: '=?',
    viewConfiguration: '=',
    niveauxAgrement: '<',
  },

  templateUrl: 'tiers/tiers-directives/agrements-demandeurs/agrement-form/agrement-form.html',
  controller: AgrementFormController,
});
