'use strict';
angular.module('portailDepotDemandeAide.dashboard', ['ui.router', 'common.filters']).config([
  '$stateProvider',
  '$urlRouterProvider',
  function ($stateProvider) {
    'use strict';

    const resolveViewConfiguration = function (module, view, namespace) {
      return [
        'viewManagerService',
        '$log',
        '$rootScope',
        function (viewManagerService, $log, $rootScope) {
          return $rootScope.configurationPromise.then(function () {
            return viewManagerService.getViewConfiguration(module, view, 'teleservice.' + namespace);
          });
        },
      ];
    };

    const resolveConfiguration = function (viewManagerService, $rootScope, module, view) {
      return $rootScope.configurationPromise.then(function () {
        return viewManagerService.getViewConfiguration(module, view);
      });
    };

    const beneficiaires = [
      'aide',
      'mdm',
      '$q',
      'tiersService',
      function (aide, mdm, $q, tiersService) {
        const isDepotDelegue = aide?.demandeur?.href !== aide?.beneficiaires?.[0]?.href;
        if (isDepotDelegue) {
          return $q.resolve(aide.beneficiaires);
        } else {
          const promises = aide.beneficiaires.map((beneficiaire, index) => {
            return tiersService
              .getTiersById(beneficiaire.href, mdm, 'pieces.documents')
              .then((expandedBeneficiaire) => {
                aide.beneficiaires[index].expand = expandedBeneficiaire;
                return expandedBeneficiaire;
              });
          });
          return $q.all(promises).then(() => aide.beneficiaires);
        }
      },
    ];

    $stateProvider
      .state('app.connected.dashboard', {
        url: '/dashboard',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          tiers: [
            'tiersService',
            'mdm',
            'selectedTiers',
            function (tiersService, mdm, selectedTiers) {
              //! don't fetch tiers twice
              return selectedTiers ?? tiersService.getCurrentTiers(mdm);
            },
          ],
        },
      })
      .state('app.connected.dashboard.accueil', {
        url: '/accueil?email&token&unauthorized&teleServiceNotFound&maximumPersistenceReach&teleserviceNotInvited&activeTab',
        component: 'dashboard',
        params: {
          activeTab: {
            dynamic: true,
          },
        },
      })
      .state('app.connected.dashboard.deposerDemandeAide', {
        url: '/deposerDemandeAide',
        controller: 'dashboardDeposerDemandeAideController',
        templateUrl: 'dashboard/deposer-demande-aide/deposer-demande-aide.html',
        resolve: {
          teleservices: [
            'teleservicesService',
            'userSessionService',
            'tiers',
            function (teleservicesService, userSessionService, tiers) {
              if (userSessionService.getUser()) {
                const sources = ['title', 'motsCles', 'workflow.familles'];
                return teleservicesService.getListTeleServices(tiers, 'simple', sources, { noInvitation: true });
              } else {
                return [];
              }
            },
          ],
          familles: [
            'tiersService',
            function (tiersService) {
              return tiersService.getFamilles();
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides', {
        url: '/aides',
        abstract: true,
        // Redirect to child state automatically https://github.com/angular-ui/ui-router/issues/948
        redirectTo: 'app.connected.dashboard.aides.demandesAides',
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.aides.demandesAides', {
        url: '/suivreDemandesAides',
        hideInBreadcrumb: true,
        redirectTo: 'app.connected.dashboard.aides.demandesAides.aides',
        component: 'tabsListDemandes',
        resolve: {
          publicSettingPortailDepot: [
            'sessionServiceAPI',
            'jwtSessionService',
            'publicSettingsService',
            '$q',
            (sessionServiceAPI, jwtSessionService, publicSettingsService, $q) => {
              sessionServiceAPI.setCurrentTiersKey('current-tiers-ref');
              sessionServiceAPI.setJwtKey(jwtSessionService.getJwtKey());
              return $q.resolve().then(() => publicSettingsService.loadPublicSettings());
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides.demandesAides.aides', {
        url: '?page',
        controller: 'dashboardDemandesAidesController',
        templateUrl: 'dashboard/aides/demandes-aides/demandes-aides.html',
        params: {
          page: {
            value: '1',
            dynamic: true,
          },
        },

        resolve: {
          defaultHelpHtmlContent: () => 'connected.dashboard.aides.demandesAides.help',
          teleservices: [
            'teleservicesService',
            'userSessionService',
            'tiers',
            (teleservicesService, userSessionService, tiers) => {
              const sources = ['id', 'justification', 'duplicationDemandeFinancement'];
              return userSessionService.getUser()
                ? teleservicesService.getListTeleServices(tiers, 'simple', sources)
                : [];
            },
          ],
          showSearch: () => true,
        },
      })
      .state('app.connected.dashboard.aides.demandesAides.sharedAides', {
        url: '/partagees?page',
        controller: 'dashboardDemandesAidesController',
        templateUrl: 'dashboard/aides/demandes-aides/demandes-aides.html',
        params: {
          page: {
            value: '1',
            dynamic: true,
          },
        },

        resolve: {
          defaultHelpHtmlContent: () => 'connected.dashboard.aides.demandesAidesSharedPage.help',
          aidesJustifications: () => {
            return null;
          },
          teleservices: [
            'teleservicesService',
            'userSessionService',
            'tiers',
            (teleservicesService, userSessionService, tiers) => {
              const sources = ['id', 'justification', 'duplicationDemandeFinancement'];
              return userSessionService.getUser()
                ? teleservicesService.getListTeleServices(tiers, 'simple', sources)
                : [];
            },
          ],
          showSearch: () => false,
        },
      })
      .state('app.connected.dashboard.aides.demandesPaiement', {
        url: '/:reference/demandesPaiement?financeur&dossier&teleservicePaiement',
        abstract: true,
        template: '<div ui-view></div>',
        params: {
          aide: null,
          teleservicePaiement: null,
        },

        resolve: {
          aide: [
            '$stateParams',
            'aidesService',
            function ($stateParams, aidesService) {
              if ($stateParams.aide) {
                return $stateParams.aide;
              } else {
                return aidesService.get($stateParams.reference);
              }
            },
          ],
          teleservicePaiement: [
            '$stateParams',
            'aide',
            'teleservicesService',
            'demandesAidesService',
            function ($stateParams, aide, teleservicesService, demandesAidesService) {
              const teleservicePaiement = _.get($stateParams, 'teleservicePaiement');
              let reference;
              if (teleservicePaiement) {
                if (!_.get(teleservicePaiement, 'reference')) {
                  // Cas du multifinanceur (dans $stateParams.teleservicePaiement, on a le Href du teleservice)
                  reference = teleservicePaiement.split('/').pop();
                  return teleservicesService.getTeleService(reference);
                }
                return teleservicesService.getTeleService(teleservicePaiement.reference);
              } else {
                const ligneRegion = demandesAidesService.findLigneFinancementDispositifEligible(aide);
                const teleservicePaiementHref = _.get(ligneRegion, 'financement.teleservicePaiement.href', '');
                reference = teleservicePaiementHref.split('/').pop();
                return teleservicesService.getTeleService(reference);
              }
            },
          ],
          demandesPaiement: [
            '$stateParams',
            'aide',
            'demandesPaiementService',
            function ($stateParams, aide, demandesPaiementService) {
              return $stateParams.dossier
                ? demandesPaiementService.getDemandesPaiementFromDossier(aide, $stateParams.dossier)
                : demandesPaiementService.getDemandesPaiementLignePlanFinancement(aide, $stateParams.financeur);
            },
          ],
          decisions: [
            '$stateParams',
            'aidesService',
            function ($stateParams, aidesService) {
              return aidesService.getDecisionsHistory($stateParams.reference, $stateParams.dossier);
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides.demandesPaiement.list', {
        url: '',
        controller: 'dashboardDemandesPaiementController',
        templateUrl: 'dashboard/aides/demandes-paiement/demandes-paiement.html',
      })
      .state('app.connected.dashboard.aides.demandesPaiement.recapitulatif', {
        url: '/:referencePaiement/recapitulatif',
        controller: 'demandePaiementRecapitulatifOnlyController',
        templateUrl: 'dashboard/aides/demandes-paiement/recapitulatif/demande-paiement-recapitulatif-only.html',
        resolve: {
          // Informations Generales
          informationsGeneralesConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'informations-generales',
            'informations-generales'
          ),

          informationsComplementairesConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'informations-complementaires',
            'informations-complementaires'
          ),

          planFinancementConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'document-comptable',
            'document-comptable'
          ),

          // Domiciliation Bancaire
          domiciliationBancaireConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'domiciliation-bancaire',
            'domiciliation-bancaire'
          ),

          // Pieces
          piecesConfiguration: resolveViewConfiguration('demandePaiement', 'pieces', 'pieces'),

          // Recapitulatif
          recapitulatifConfiguration: resolveViewConfiguration('demandePaiement', 'recapitulatif', 'recapitulatif'),

          // Confirmation
          confirmationConfiguration: resolveViewConfiguration('demandePaiement', 'confirmation', 'confirmation'),

          demandePaiement: [
            '$stateParams',
            'demandesPaiementService',
            function ($stateParams, demandesPaiementService) {
              return demandesPaiementService.getDemandePaiement($stateParams.referencePaiement, {
                params: {
                  $expand: 'pieces.documents,domiciliationBancaire.pieces.documents,demandeFinancement.teleservice',
                },
              });
            },
          ],
          // Configuration du teleservice
          teleserviceConfiguration: [
            '$state',
            '$translate',
            '$location',
            'teleservicesService',
            'configuration',
            'teleservicePaiement',
            function ($state, $translate, $location, teleservicesService, configuration, teleservicePaiement) {
              return teleservicesService
                .getTeleService(_.get(teleservicePaiement, 'reference'), false)
                .then(function (teleservice) {
                  // Modify by reference i18n's configuration and force the refresh.
                  // Necessary to fetch files with dynamic names based on current teleservice
                  configuration.i18n.teleservice.id = _.get(teleservicePaiement, 'reference');

                  if (teleservice) {
                    configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                    return $translate.refresh().then(function () {
                      teleservice.workflow = teleservice.workflow[teleservice.workflow.type];
                      // copy type to control access in controller
                      teleservice.workflow.type = configuration.i18n.teleservice.workflow;
                      return teleservice;
                    });
                  } else {
                    return $translate.refresh().then(function () {
                      return {};
                    });
                  }
                });
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides.demandesPaiement.complements', {
        url: '/:referencePaiement/complementsPaiement',
        controller: 'dashboardPaiementsDemandesComplementPiecesController',
        templateUrl:
          'dashboard/aides/demandes-paiement/demandes-complement-pieces/demandes-paiement-complement-pieces.html',
        resolve: {
          demandePaiement: [
            'demandesPaiementService',
            '$stateParams',
            function (demandesPaiementService, $stateParams) {
              return demandesPaiementService.getDemandePaiement($stateParams.referencePaiement, {
                params: { $expand: 'pieces.documents' },
              });
            },
          ],
          teleservicePaiement: [
            'demandePaiement',
            'teleservicePaiement',
            'teleservicesService',
            /**
             * Load the teleservicePaiement if it was not already load
             * Case : teleservice is set on dispositif after VOTE of dossier
             *
             * @param {object} demandePaiement The demandePaiement
             * @param {object | undefined} teleservicePaiement The already retrieved teleservice if there is one
             * @param {object} teleservicesService The service of teleservice
             * @returns {Promise<object>} The teleservice
             */
            function (demandePaiement, teleservicePaiement, teleservicesService) {
              if (teleservicePaiement) {
                return teleservicePaiement;
              }

              const reference = demandePaiement.teleservicePaiement.href.split('/').pop();
              return teleservicesService.getTeleService(reference);
            },
          ],
          aideComplementsConfiguration: [
            'viewManagerService',
            '$rootScope',
            function (viewManagerService, $rootScope) {
              return resolveConfiguration(viewManagerService, $rootScope, 'aides', 'complements');
            },
          ],
          previousState: [
            '$state',
            function ($state) {
              const params = angular.copy($state.params);
              delete params['#'];
              return {
                name: $state.current.name,
                params: params,
              };
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides.complements', {
        url: '/:reference/complements',
        controller: 'dashboardDemandesComplementPiecesController',
        templateUrl: 'dashboard/aides/demandes-complement-pieces/demandes-complement-pieces.html',
        params: {
          page: null,
          aide: null,
        },
        resolve: {
          aide: [
            '$stateParams',
            'aidesService',
            'mdm',
            function ($stateParams, aidesService, mdm) {
              const properties = `demandesComplementPieces,pieces.documents,teleservice`;
              return aidesService.get($stateParams.reference, mdm, properties);
            },
          ],
          teleservice: [
            'aide',
            'teleservicesService',
            function (aide, teleservicesService) {
              return teleservicesService.getTeleServiceFromObject(aide);
            },
          ],
          beneficiaires,
          aideComplementsConfiguration: [
            'viewManagerService',
            '$rootScope',
            function (viewManagerService, $rootScope) {
              return resolveConfiguration(viewManagerService, $rootScope, 'aides', 'complements');
            },
          ],
          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            function (aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],

          previousState: [
            '$state',
            function ($state) {
              const params = angular.copy($state.params);
              delete params['#'];
              return {
                name: $state.current.name,
                params: params,
              };
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides.demandedepotpieces', {
        url: '/:reference/depotpieces',
        controller: 'dashboardDemandeDepotPiecesController',
        templateUrl: 'dashboard/aides/demandes-depot-pieces/demandes-depot-pieces.html',
        params: {
          page: null,
        },
        resolve: {
          aide: [
            '$stateParams',
            'aidesService',
            'mdm',
            function ($stateParams, aidesService, mdm) {
              return aidesService.get($stateParams.reference, mdm, 'pieces.documents,teleservice');
            },
          ],
          teleservice: [
            'aide',
            'teleservicesService',
            function (aide, teleservicesService) {
              return teleservicesService.getTeleServiceFromObject(aide);
            },
          ],
          beneficiaires,
          piecesConfiguration: [
            'viewManagerService',
            '$rootScope',
            function (viewManagerService, $rootScope) {
              return resolveConfiguration(viewManagerService, $rootScope, 'aides', 'depotpieces');
            },
          ],
          publicSettingsFinancement: [
            'aidesService',
            function (aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
        },
      })
      .state('app.connected.dashboard.recapitulatif', {
        url: '/:aide/recapitulatif?fromContribution&fromAttestations&fromEchange&fromAvisPrealables&fromAvis&fromAvisCompleted&statutContribution&from&activeFilters',
        controller: 'aideRecapitulatifController',
        templateUrl: 'dashboard/recapitulatif/recapitulatif.html',
        params: {
          page: null,
        },

        resolve: {
          contribution: [
            '$stateParams',
            'aidesService',
            'contributionsService',
            function ($stateParams, aidesService, contributionsService) {
              // don't resolve contribution when accessing echanges
              if ($stateParams.fromEchange) return;

              const hrefAide = aidesService.getAideHref($stateParams.aide);
              return contributionsService.getContributionModificationOrRedirectionNotClosedForDemande(hrefAide);
            },
          ],
          aide: [
            '$state',
            '$stateParams',
            '$translate',
            'mdm',
            'recapitulatifService',
            'contribution',
            function ($state, $stateParams, $translate, mdm, recapitulatifService, contribution) {
              let params = {};
              if (contribution) {
                params = { contribution: contribution.reference };
              }

              return recapitulatifService.getRecap($stateParams.aide, mdm, params).catch((err) => {
                let message = '';
                switch (err.status) {
                  case 403:
                    message = 'connected.depot.errors.restrictedDemande';
                    break;
                  default:
                    message = 'aides.recapitulatif-aide.errors.unknown';
                }

                $state.go('app.connected.dashboard.aides.demandesAides', $stateParams, { reload: true }).then(() => {
                  NotificationCenter.postMessage({
                    context: message,
                    content: $translate.instant(message),
                    variant: 'danger',
                  });
                });
              });
            },
          ],
          teleservice: [
            'aide',
            'teleservicesService',
            'configuration',
            '$translate',
            function (aide, teleservicesService, configuration, $translate) {
              return teleservicesService.getTeleServiceFromObject(aide).then(function (teleservice) {
                configuration.i18n.teleservice.id = teleservice.reference;
                configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                return $translate.refresh().then(function () {
                  return teleservice;
                });
              });
            },
          ],
          isAccessedThroughSharing: [
            'aidesService',
            'aide',
            'tiers',
            'sessionServiceAPI',
            'jwtSessionService',
            (aidesService, aide, tiers, sessionServiceAPI, jwtSessionService) => {
              sessionServiceAPI.setCurrentTiersKey('current-tiers-ref');
              sessionServiceAPI.setJwtKey(jwtSessionService.getJwtKey());
              return aidesService.isAccessedThroughSharing(aide, tiers);
            },
          ],
          displayContributionsInformation: [
            'isAccessedThroughSharing',
            '$stateParams',
            'contributionsService',
            '$translate',
            function (isAccessedThroughSharing, $stateParams, contributionsService, $translate) {
              // In sharing mode, contributionsInformation section is never displayed
              if (isAccessedThroughSharing) return false;

              return contributionsService
                .countByDemandeFinancement($stateParams.aide)
                .then((contributionsInformationCount) => {
                  return contributionsInformationCount > 0;
                })
                .catch((error) => {
                  console.error(error);
                  NotificationCenter.postMessage({
                    context: 'contributions-information',
                    content: $translate.instant('common.error.happened'),
                    variant: 'danger',
                  });
                  return false;
                });
            },
          ],
          echangesActif: [
            'aide',
            'teleservicesService',
            'isAccessedThroughSharing',
            function (aide, teleservicesService, isAccessedThroughSharing) {
              // PLAID-66072, RG 2-08: no access to echanges in sharing mode
              if (isAccessedThroughSharing) return false;

              return teleservicesService.getTeleServiceFromObject(aide, true).then(function (teleservice) {
                return teleservice.echangesActif;
              });
            },
          ],
          teleservicePaiement: [
            '$stateParams',
            'aide',
            'teleservicesService',
            'demandesAidesService',
            function ($stateParams, aide, teleservicesService, demandesAidesService) {
              const teleservicePaiement = _.get($stateParams, 'teleservicePaiement');
              let reference;
              if (!teleservicePaiement) {
                const ligneRegion = demandesAidesService.findLigneFinancementDispositifEligible(aide);
                const teleservicePaiementHref = _.get(ligneRegion, 'financement.teleservicePaiement.href', '');
                reference = teleservicePaiementHref.split('/').pop();
                return teleservicesService.getTeleService(reference);
              }

              if (!_.get(teleservicePaiement, 'reference')) {
                // Cas du multifinanceur (dans $stateParams.teleservicePaiement, on a le Href du teleservice)
                reference = teleservicePaiement.split('/').pop();
                return teleservicesService.getTeleService(reference);
              }
              return teleservicesService.getTeleService(teleservicePaiement.reference);
            },
          ],
          demandesPaiement: [
            '$stateParams',
            'aide',
            'demandesPaiementService',
            function ($stateParams, aide, demandesPaiementService) {
              return demandesPaiementService.getDemandesPaiementLignePlanFinancement(aide, $stateParams.financeur);
            },
          ],
          decisions: [
            '$stateParams',
            'aidesService',
            function ($stateParams, aidesService) {
              return aidesService.getDecisionsHistory($stateParams.aide);
            },
          ],
        },

        onEnter: [
          '$rootScope',
          '$stateParams',
          'echangesActif',
          function ($rootScope, $stateParams, echangesActif) {
            $rootScope.echangesActif = echangesActif;
            $rootScope.displayEchangeMenu = { value: echangesActif, demandeId: $stateParams.aide };
          },
        ],
        onExit: [
          '$rootScope',
          function ($rootScope) {
            $rootScope.displayEchangeMenu = { value: false };
          },
        ],
      })
      .state('app.connected.dashboard.contributionsAvisPrealable', {
        url: '/contributionsAvisPrealable',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.contributionsAvisPrealable.list', {
        url: '/:typeAvis',
        controller: [
          '$state',
          function ($state) {
            $state.go('app.connected.dashboard.sollicitations', {
              activeTab: 'avis-prealables',
            });
          },
        ],
      })
      .state('app.connected.dashboard.contributionsAvisPrealable.avis', {
        url: '/:typeAvis',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.contributionsAvisPrealable.avis.recapitulatif', {
        url: '/:aide/recapitulatif?activeFilters',
        controller: 'aideRecapitulatifController',
        templateUrl: 'dashboard/recapitulatif/recapitulatif.html',
        resolve: {
          // contribution need to be resolved to show the page
          contribution: () => null,
          aide: [
            '$stateParams',
            'mdm',
            'avisPrealablesService',
            function ($stateParams, mdm, avisPrealablesService) {
              return avisPrealablesService.getContributionPourAvisPrealables($stateParams.aide, mdm);
            },
          ],
          teleservice: [
            'aide',
            'teleservicesService',
            'configuration',
            '$translate',
            function (aide, teleservicesService, configuration, $translate) {
              return teleservicesService.getTeleServiceFromObject(aide).then(function (teleservice) {
                configuration.i18n.teleservice.id = teleservice.reference;
                configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                return $translate.refresh().then(function () {
                  return teleservice;
                });
              });
            },
          ],
          teleservicePaiement: [
            '$stateParams',
            'aide',
            'teleservicesService',
            'demandesAidesService',
            function ($stateParams, aide, teleservicesService, demandesAidesService) {
              const teleservicePaiement = _.get($stateParams, 'teleservicePaiement');
              let reference;
              if (!teleservicePaiement) {
                const ligneRegion = demandesAidesService.findLigneFinancementDispositifEligible(aide);
                const teleservicePaiementHref = _.get(ligneRegion, 'financement.teleservicePaiement.href', '');
                reference = teleservicePaiementHref.split('/').pop();
                return teleservicesService.getTeleService(reference);
              }

              if (!_.get(teleservicePaiement, 'reference')) {
                // Cas du multifinanceur (dans $stateParams.teleservicePaiement, on a le Href du teleservice)
                reference = teleservicePaiement.split('/').pop();
                return teleservicesService.getTeleService(reference);
              }
              return teleservicesService.getTeleService(teleservicePaiement.reference);
            },
          ],
          displayContributionsInformation: [() => false], // no contributionsInformation can exist on this status
          demandesPaiement: [
            '$stateParams',
            'aide',
            'demandesPaiementService',
            function ($stateParams, aide, demandesPaiementService) {
              return demandesPaiementService.getDemandesPaiementLignePlanFinancement(aide, $stateParams.financeur);
            },
          ],
          decisions: [
            'aide',
            'aidesService',
            function (aide, aidesService) {
              return aidesService.getDecisionsHistory(aide.reference);
            },
          ],

          isAccessedThroughSharing: [() => false], // there is no sharings for contribution avis prealable, so don't need to check
        },
      })
      .state('app.connected.dashboard.contributionsSearch', {
        url: '/contributionsSearch',
        component: 'contributionsSearch',
      })
      .state('app.connected.dashboard.contributions', {
        url: '/contributions',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.contributions.recapitulatif', {
        url: '/:referenceContribution/recapitulatif?activeFilters',
        component: 'contributionsRecapitulatif',
        resolve: {
          // Contribution
          contribution: [
            '$stateParams',
            'contributionsService',
            function ($stateParams, contributionsService) {
              return contributionsService.get($stateParams.referenceContribution, [
                'demande',
                'tiers',
                'contact',
                'teleservice',
                'user',
                'avis.pieces.documents',
              ]);
            },
          ],

          teleservice: [
            'contribution',
            'teleservicesService',
            'configuration',
            '$translate',
            function (contribution, teleservicesService, configuration, $translate) {
              return teleservicesService.getTeleServiceFromObject(contribution).then(function (teleservice) {
                configuration.i18n.teleservice.id = teleservice.reference;
                configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                return $translate.refresh().then(function () {
                  return teleservice;
                });
              });
            },
          ],
          contributionConfiguration: [
            'viewManagerService',
            '$rootScope',
            'contribution',
            'teleservice',
            function (viewManagerService, $rootScope, contribution, teleservice) {
              if (contribution.typeContribution === 'AVIS') {
                return resolveConfiguration(viewManagerService, $rootScope, 'contributionAvis', 'avis');
              } else {
                return _.get(teleservice, 'workflow.contributionAvisFinancement.pageContributionAvisFinancement');
              }
            },
          ],
          // contributionAvis
          avisConfiguration: resolveViewConfiguration('contributionAvis', 'avis', 'avis'),
          // Pieces
          piecesConfiguration: resolveViewConfiguration('contributionAvis', 'pieces', 'pieces'),
          activeFilters: [
            '$state',
            function ($state) {
              return $state.params.activeFilters;
            },
          ],
        },
      })
      .state('app.connected.dashboard.contributionsModificationList', {
        url: '/contributions/modifications/:demande',
        component: 'contributionsModifications',
        params: {
          page: null,
        },

        resolve: {
          contributionsDepart: [
            '$stateParams',
            'contributionsService',
            function ($stateParams, contributionsService) {
              const contribResults = {
                contributions: [],
                totalContributions: 0,
              };

              return contributionsService
                .searchModificationsForDemande($stateParams.demande, 0)
                .then(({ contributions, total }) => {
                  const { treatedContributions, untreatedContributions } =
                    contributionsService.differenciateTreatedFromUntreated(contributions);

                  contribResults.contributions = untreatedContributions
                    .sort(contributionsService.sortByDateFunction)
                    .concat(treatedContributions.sort(contributionsService.sortByDateFunction));
                  contribResults.totalContributions = total;
                  return contribResults;
                });
            },
          ],
          /**
           * Resolve pour le breadcrumb
           *
           * @param {object} $stateParams
           * @param {object} aidesService
           * @returns {object}
           */
          demande: [
            '$stateParams',
            'aidesService',
            function ($stateParams, aidesService) {
              return aidesService.get($stateParams.demande);
            },
          ],
        },
      })
      .state('app.connected.dashboard.echanges', {
        url: '/echanges',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.echanges.centreEchanges', {
        url: '/centreEchanges',
        controller: 'dashboardCentreEchangesController',
        templateUrl: 'dashboard/echanges/centre-echanges/centre-echanges.html',
        resolve: {
          isEchangesLicenceActive: [
            'sessionServiceAPI',
            'jwtSessionService',
            function (sessionServiceAPI, jwtSessionService) {
              sessionServiceAPI.setJwtKey(jwtSessionService.getJwtKey());
              return sessionServiceAPI.isLicenseActive('echanges-fils-discussion');
            },
          ],
        },
      })
      .state('app.connected.dashboard.attestations', {
        url: '/attestations',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.documentsReçus', {
        url: '/my',
        controller: 'dashboardMesPublicationsController',
        controllerAs: '$ctrl',
        templateUrl: 'dashboard/publication/mes-publications/mes-publications.html',
      })
      .state('app.connected.dashboard.attestations.confirmation', {
        url: '/confirmation?attestations',
        controller: 'dashboardConfirmationAttestationsController',
        templateUrl: 'dashboard/attestations/confirmation-attestations/confirmation-attestations.html',
        resolve: {
          attestationsTransmises: [
            'aidesService',
            '$stateParams',
            function (aidesService, $stateParams) {
              return aidesService.getAidesByReference($stateParams.attestations);
            },
          ],
        },
      })
      .state('app.connected.dashboard.justifications', {
        abstract: true,
        url: '/justifications',
        redirectTo: 'app.connected.dashboard.justifications.search',
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.justification', {
        url: '/justification/:justificationReference?recap',
        component: 'justificationComponent',
        resolve: {
          justificationAndExpandedPieces: [
            '$stateParams',
            'justificationService',
            function ($stateParams, justificationService) {
              const select = [
                // Common
                'date',
                'active',
                'demandeFinancement',
                'kind',
                'reference',
                'statut',
                'teleservice',
                'tenant',
                'title',
                'user',
                'history',
                // Informations de réalisation - Informations Générales
                'miseEnOeuvre',
                'questionBeneficiaire',
                'beneficiaires',
                'nbBeneficiaires',
                'nbQuartiersPrioritaires',
                'dateDebut',
                'dateFin',
                'lieux',
                'objectifs',
                // Informations de réalisation - Informations Complémentaires
                'views',
                // Informations de réalisation - Moyens humains
                'moyensHumains',
                // Tableau de synthèse
                'tableau',
                // Données chiffrées
                'reglesRepartition',
                'explicationEcarts',
                'contributionsVolontaires',
                'observations',
                // Pieces
                // Recapitulatif
                'pieces',
                // Raison non réalisation (Téléservice NRA)
                'raisonNonRealisation',
              ];

              return justificationService.getJustificationAndExpandedPieces(
                $stateParams.justificationReference,
                select
              );
            },
          ],

          justification: [
            'justificationAndExpandedPieces',
            (justificationAndExpandedPieces) => justificationAndExpandedPieces.justification,
          ],

          piecesExpanded: [
            'justificationAndExpandedPieces',
            (justificationAndExpandedPieces) => justificationAndExpandedPieces.piecesExpanded,
          ],

          teleserviceJustification: [
            'justification',
            'justificationService',
            function (justification, justificationService) {
              return justificationService.getTeleservice(justification, [
                'workflow',
                'typeWorkflow',
                'allowedExtensions',
                'maxDocumentSize',
                'maxNumberDocuments',
                'documentComptable',
              ]);
            },
          ],

          /* @ngInject */
          demandeFinancement: [
            'justification',
            '$http',
            '$log',
            (justification, $http, $log) =>
              $http
                .get(`${_.get(justification, 'demandeFinancement.href')}?expand=teleservice`)
                .then((aide) => _.get(aide, 'data'))
                .catch((error) => {
                  $log.error(`Can't get demandeFinancement from justification ${error}`);
                }),
          ],
        },
      })
      .state('app.connected.dashboard.saisieDemandeReport', {
        url: '/demandes-report/:demandeReference',
        component: 'saisieDemandeReportComponent',
        resolve: {
          demandeReference: ['$stateParams', ($stateParams) => $stateParams.demandeReference],
        },
      })
      .state('app.connected.dashboard.sollicitations', {
        url: '/sollicitations?activeTab&page&activeFilters',
        component: 'sollicitations',
        params: {
          activeTab: {
            dynamic: true,
          },
          page: {
            dynamic: true,
          },
          activeFilters: {
            dynamic: true,
          },
        },
        resolve: {
          hasTeleserviceWithActiveJustification: [
            'teleservicesService',
            'tiers',
            async function (teleservicesService, tiers) {
              const sources = ['justification'];
              const allTeleservices = await teleservicesService.getListTeleServices(tiers, 'simple', sources);
              return allTeleservices.some((ts) => ts.justification?.active);
            },
          ],
          isJustificationsLicenceActive: [
            'sessionServiceAPI',
            'jwtSessionService',
            function (sessionServiceAPI, jwtSessionService) {
              sessionServiceAPI.setJwtKey(jwtSessionService.getJwtKey());
              return sessionServiceAPI.isLicenseActive('bilan-justification');
            },
          ],
        },
      })
      .state('app.connected.dashboard.demandesApprenants', {
        url: '/suivreDemandesApprenants?activeTab&page&activeFilters',
        component: 'demandesApprenantsListComponent',
        params: {
          activeTab: {
            dynamic: true,
          },
          page: {
            value: '1',
            dynamic: true,
          },
          activeFilters: {
            dynamic: true,
          },
        },
      })
      .state('app.connected.dashboard.demandeApprenantRecapitulatif', {
        url: '/demandes-apprenants/:demandeFinancementReference/recapitulatif?fromTab&fromPage&activeFilters',
        component: 'demandeApprenantRecapitulatifComponent',
        resolve: {
          demandeFinancementReference: ['$stateParams', ($stateParams) => $stateParams.demandeFinancementReference],
        },
      })
      .state('app.connected.dashboard.entreeFormationRecapitulatif', {
        url: '/entrees-formation/:demandeFinancementReference/recapitulatif?fromTab&fromPage&activeFilters',
        component: 'demandeApprenantRecapitulatifComponent',
        resolve: {
          demandeFinancementReference: ['$stateParams', ($stateParams) => $stateParams.demandeFinancementReference],
          fromEntreeFormation: () => true,
        },
      })
      .state('app.connected.dashboard.entreesFormation', {
        url: '/suivreEntreesFormation?activeTab&page&activeFilters',
        component: 'contributionsVEFListComponent',
        params: {
          activeTab: {
            dynamic: true,
          },
          page: {
            value: '1',
            dynamic: true,
          },
          activeFilters: {
            dynamic: true,
          },
        },
      })
      .state('app.connected.dashboard.contributionInformationRecapitulatif', {
        url: '/contributions-information/:contributionInformationReference/recapitulatif',
        component: 'contributionInformationRecapitulatifComponent',
        resolve: {
          contributionInformationReference: [
            '$stateParams',
            ($stateParams) => $stateParams.contributionInformationReference,
          ],
        },
      });
  },
]);
