'use strict';
/**
 * @module portailDepotDemandeAide
 * @name demandePaiementController
 * @description
 *
 *   This controller manages a series of page for 'demandePaiement' model of procedure
 */

/*jshint maxparams:false */
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementController', [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  '$stateParams',
  '$log',
  '$location',
  '$interval',
  '$q',
  '$translate',
  'demandesAidesService',
  'demandesPaiementService',
  'userSessionService',
  'suiviFinancementService',
  'aide',
  'typesPaiement',
  'demandePaiement',
  'AUTHENTICATION_EVENTS',
  'configuration',
  'decisions',
  'alertsService',
  'StoreService',
  'pagesToDisplay',
  'socketService',
  'lockEntitiesService',
  'lockingSocket',
  function (
    $scope,
    $rootScope,
    $window,
    $state,
    $stateParams,
    $log,
    $location,
    $interval,
    $q,
    $translate,
    demandesAidesService,
    demandesPaiementService,
    userSessionService,
    suiviFinancementService,
    aide,
    typesPaiement,
    demandePaiement,
    AUTHENTICATION_EVENTS,
    configuration,
    decisions,
    alertsService,
    StoreService,
    pagesToDisplay,
    socketService,
    lockEntitiesService,
    lockingSocket
  ) {
    ('use strict');

    $scope.aide = aide;
    $scope.decisions = decisions;
    $scope.initializeChild = function () {
      $scope.sharedWithChild = {};
    };
    $scope.initializeChild();
    // 2 minutes
    const INTERVAL_AUTOMATIC_UPDATE_SECONDS =
      configuration.depot?.demandePaiement?.automaticUpdate?.intervalInSeconds ?? 120;

    if (aide.cloture?.value) {
      NotificationCenter.postMessage({
        content: $translate.instant('connected.depot.errors.demandePaiementCannotBeCreatedOrUpdate'),
        variant: 'danger',
      });
    }

    if ($stateParams.dossier) {
      const ligneDossier = suiviFinancementService.getLigneWithDossier(aide, $stateParams.dossier);
      $scope.dossierFinancement = {
        href: _.get(ligneDossier, 'financement.source.href'),
        referenceAdministrative: _.get(ligneDossier, 'financement.source.referenceAdministrative'),
        title: _.get(ligneDossier, 'financement.source.title'),
        montantVote:
          _.get(ligneDossier, 'financement.montantVote.ttc') || _.get(ligneDossier, 'financement.montantVote.ht') || 0,
      };
    }

    if ($scope.aide.multiFinanceur) {
      $scope.financeur = _.find($scope.aide.multiFinancement.financeurs, ['href', $stateParams.financeurHref]);
    }

    $scope.demandePaiement = demandePaiement;
    $scope.demandePaiement.demandeFinancement = $scope.demandePaiement.demandeFinancement || {
      href: aide.id,
      expand: aide,
    };

    if ($scope.demandePaiement?.statut !== 'EN_COURS') {
      // if demande paiement is already transmitted, we rediect to recap page
      $scope.redirectToRecap = true;
      $state.go('app.connected.dashboard.aides.demandesPaiement.recapitulatif', {
        reference: $scope.demandePaiement.demandeFinancement.expand.reference,
        referencePaiement: $scope.demandePaiement.reference,
        teleservicePaiement: ($scope.demandePaiement.teleservicePaiement.id || '').split('/').pop(),
        financeur: _.get($scope, 'financeur.href'),
      });
    } else {
      $scope.redirectToRecap = false;
    }

    /**
     * Méthode qui permet de récupérer la ligne de financement du financeur
     *
     * @param  {object} aide Aide
     * @param  {object} financeurHref financeurHref
     * @returns {object}      Ligne de financement
     */
    var findLigneFinancementFinanceur = function (aide, financeurHref) {
      var lignePF;
      _.each(aide.planFinancement, function (planFinancement) {
        var postes = _.get(planFinancement, 'recette.postes');
        _.each(postes, function (poste) {
          _.each(_.get(poste, 'lignes'), function (ligne) {
            if (_.get(ligne, 'financement.financeur.href') === financeurHref) {
              lignePF = ligne;
            }
          });
        });
      });
      return lignePF;
    };

    if (demandesAidesService.isMultiFinanceursWithoutFinanceurPrincipal(aide)) {
      // 'financeur.href' (si déjà un paiement sur ce financeur), sinon 'financeurHref'
      var financeurHref = _.get($stateParams, 'financeurHref');
      var lignePF = findLigneFinancementFinanceur(aide, financeurHref);
      $scope.demandePaiement.dossierFinancement = {
        href: _.get(lignePF, 'financement.source.href'),
      };
    } else {
      const dossierFinancementHref = $scope.dossierFinancement
        ? $scope.dossierFinancement.href
        : JSONPath(
            '$.demandeFinancement.expand.planFinancement..recette..lignes..[?(@.financement && @.financement.source && @.financement.source.href)].financement.source.href',
            $scope.demandePaiement
          )[0];
      $scope.demandePaiement.dossierFinancement = {
        href: dossierFinancementHref,
      };
    }

    $scope.demandePaiement.linkedUsers = $scope.demandePaiement.linkedUsers || [];

    // Add begin event to entity if not present
    if (!_.get($scope.demandePaiement, 'history.begin')) {
      $scope.demandePaiement.history = {
        begin: {
          summary: 'Creation of the entity - Status ' + $scope.demandePaiement.statut,
          user: {
            href: configuration.user.accountManagement + /users/ + _.get(userSessionService.getUser(), 'userName'),
            method: 'GET',
          },

          date: new Date(),
          metadata: {
            step: 'preambule',
            stepsStack: [],
          },
        },
      };

      var user = {
        title: $scope.currentUser.displayName,
        rel: 'ADMINISTRATOR',
        href: configuration.user.accountManagement + /users/ + $scope.currentUser.userName,
        method: 'GET',
        form: 'ADMINISTRATOR',
      };

      $scope.demandePaiement.linkedUsers.push(user);
    }

    // Manage step navigations
    $scope.$watch('demandePaiement.history.begin.metadata.step', function (newStep) {
      $scope.actif = false;
      $scope.newStep = newStep;

      // label of the page
      var depotState = $state.$current;
      depotState.data = $state.$current.data || {};
      $rootScope.pageConfigurationKey =
        ($scope.teleserviceConfiguration.libelle && $scope.teleserviceConfiguration.libelle.value) ||
        $scope.teleserviceConfiguration.reference;
      delete depotState.data.title;

      if (newStep && newStep !== 'preambule' && newStep !== 'confirmation') {
        // Will either create of update a persistence based on whether the _id key is present
        $scope.continue = false;
        return $scope.save().then(function () {
          $scope.continue = true;
        });
      }
    });

    // Add the reference in the url, when the demande is created by saveDemandePaiement
    $scope.$watch('demandePaiement.reference', function (newReference, oldReference) {
      if (newReference && !oldReference) {
        $location.search('reference', newReference);
      }
    });

    /// Navigation
    /**
     * Go back to the previous step filled
     *
     * @returns {void}
     */
    $scope.navigate.goToPreviousStep = function () {
      $scope.$emit('hideModalsAndErrors');
      // Get to last element of the stack of steps and remove it
      $scope.demandePaiement.history.begin.metadata.step =
        $scope.demandePaiement.history.begin.metadata.stepsStack.pop();
      $scope.cleanNavigate();
      $window.scrollTo(0, 0);
    };

    /**
     * Make the action to go to the previous step with a save before if defined
     *
     * @returns {Promise<void> | void}
     */
    $scope.navigate.previous = function () {
      if ($scope.sharedWithChild.beforePrevious) {
        return $scope.sharedWithChild.beforePrevious().then(() => $scope.navigate.goToPreviousStep());
      }

      return $scope.navigate.goToPreviousStep();
    };

    /**
     * Change the step of the demande paiement
     *
     * @param {string} step The step to navigate to
     * @param {boolean} forget True if we must keep it to history for previous purpose
     */
    $scope.changeStep = function (step, forget) {
      $scope.$emit('hideModalsAndErrors');
      // The forget parameter can be used to skip adding the step to the steps history stack
      // Usefull when a step wants to skip itself entirely
      if (!forget) {
        $scope.demandePaiement.history.begin.metadata.stepsStack.push(
          $scope.demandePaiement.history.begin.metadata.step
        );
      }
      $scope.demandePaiement.history.begin.metadata.step = step;
      $scope.cleanNavigate();
      // Prevent keeping the scroll offset accross pages
      $window.scrollTo(0, 0);
    };

    /**
     * Refresh the originalDemandePaiement
     *
     * @param {object} params Query parameters to add to the http get
     * @returns {Promise<void>}
     */
    $scope.refreshDemandePaiement = function (params = {}) {
      if (!$scope.demandePaiement.reference) {
        return $q.resolve();
      }

      return demandesPaiementService
        .getDemandePaiement($scope.demandePaiement.reference, {
          params,
        })
        .then(function (demandePaiement) {
          $scope.demandePaiement.dossierFinancement = demandePaiement?.dossierFinancement;
          $scope.originalDemandePaiement.dossierFinancement = $scope.demandePaiement.dossierFinancement;

          if (demandePaiement?.attributaire?.expand) {
            $scope.demandePaiement.attributaire = demandePaiement.attributaire;
            $scope.originalDemandePaiement.attributaire = $scope.demandePaiement.attributaire;
          }

          $scope.originalDemandePaiement = angular.copy(demandePaiement);
          $scope.originalDemandePaiement.attributaire = $scope.demandePaiement.attributaire;
        });
    };

    /**
     * Small helper function for the 'next' children navigation functions
     *
     * @param {string} step The step to navigate to
     * @param {boolean} forget True if we must keep it to history for previous purpose
     * @returns {Promise<void>}
     */
    $scope.goToStep = function (step, forget) {
      if (step && !['preambule', 'confirmation'].includes(step)) {
        // Get demande paiement dossier financement expand
        // check if aide is V8 and not not multi financeurs without financeur principal
        if (
          !demandesAidesService.isMultiFinanceursWithoutFinanceurPrincipal(aide) &&
          _.has($scope, 'demandePaiement.reference') &&
          (!_.has($scope, 'demandePaiement.dossierFinancement.expand') ||
            !_.has($scope, 'demandePaiement.attributaire.expand'))
        ) {
          const hasCorrelationsV8 = $scope.aide?.correlations?.some((correlation) =>
            correlation.value.startsWith('/connecteur-aides-v9v8')
          );
          //if not v8 add dispositif expand
          if (!hasCorrelationsV8) {
            const promise = $scope
              .refreshDemandePaiement({
                $expand: 'dossierFinancement.dispositif,attributaire',
              })
              .finally(() => {
                return $scope.changeStep(step, forget);
              });
            return StoreService.demandePaiement.pendingPromises.push(promise);
          }
        }
      }
      return $scope.changeStep(step, forget);
    };

    // Same as gotToStep but returns a function for later execution, makes it possible to use oneliners for navigate.next definitions
    $scope.goToStepFn = function (step) {
      return function (forget) {
        $scope.goToStep(step, forget);
      };
    };

    $scope.activePage = function (actif) {
      $scope.actif = actif || true;
      $state.$current.data.title = 'teleservice.' + $scope.newStep + '.title';
    };

    $scope.navigate.backToDemandesPaiementList = function (demandePaiement) {
      if ($scope.isAccessedThroughSharing()) {
        $state.go('app.connected.dashboard.aides.demandesAides.sharedAides');
      } else {
        $state.go('app.connected.dashboard.aides.demandesPaiement.list', {
          reference: demandePaiement.demandeFinancement.expand.reference,
          aide: $scope.aide,
          financeur: $scope.financeur?.href,
          teleservicePaiement: (demandePaiement.teleservicePaiement.id || '').split('/').pop(),
          configurationId: demandePaiement.teleservicePaiement.id,
          dossier: $stateParams.dossier,
        });
      }
    };

    /**
     * Prepare steps that match the requirement for almost all cases
     * For more specific behavior, every controller should do its work
     *
     * @returns {string[]} all step progress for demande paiement
     */
    $scope.getDemandePaiementSteps = function () {
      const steps = [];
      if (_.get($scope.teleserviceConfiguration, 'workflow.pagePreambule.actif', false)) {
        steps.push('preambule');
      }
      steps.push('informationsGenerales');
      if (_.get($scope.teleserviceConfiguration, 'workflow.pageDomiciliationBancaire.actif', false)) {
        steps.push('domiciliationBancaire');
      }
      if (_.get($scope.teleserviceConfiguration, 'workflow.pagePieces.actif', false)) {
        steps.push('pieces');
      }
      steps.push('recapitulatif');
      return steps;
    };

    $scope.showShareButton = function () {
      return demandesPaiementService.canBeShared($scope.demandePaiement);
    };

    $scope.isAccessedThroughSharing = function () {
      return demandesPaiementService.isAccessedThroughSharing($scope.demandePaiement);
    };

    // Types de paiement du référentiel-financement
    $scope.typesPaiement = typesPaiement;

    // Check if all informations complementaires are agentOnly
    $scope.displayInfosComplementaires = pagesToDisplay.informationsComplementaires;

    // variable use to say if the "indicateur" page should be dispay
    $scope.displayIndicateursRealisation = pagesToDisplay.indicateurs;

    /**
     * Standard configuration
     *
     * @param {object} entity
     * @param {string} step
     * @returns {object}
     */
    function createConfiguration(entity, step) {
      return _.merge(
        {
          ns: 'teleservice.' + step,
        },

        _.get($scope.teleserviceConfiguration, entity + '.' + step)
      );
    }
    $scope.preambuleConfiguration = createConfiguration('demandePaiement', 'preambule');
    $scope.informationsGeneralesConfiguration = createConfiguration('demandePaiement', 'informations-generales');
    $scope.informationsComplementairesConfiguration = createConfiguration(
      'demandePaiement',
      'informations-complementaires'
    );

    $scope.indicateursRealisationConfiguration = createConfiguration('demandePaiement', 'indicateurs');
    $scope.documentComptableConfiguration = createConfiguration('demandePaiement', 'document-comptable');
    $scope.domiciliationBancaireConfiguration = createConfiguration('aide', 'domiciliation-bancaire');
    $scope.piecesConfiguration = createConfiguration('demandePaiement', 'pieces');
    $scope.recapitulatifConfiguration = createConfiguration('demandePaiement', 'recapitulatif');
    $scope.confirmationConfiguration = createConfiguration('demandePaiement', 'confirmation');

    $scope.originalDemandePaiement = angular.copy($scope.demandePaiement);

    /**
     * Retrieve the patches to apply to the demande-paiement
     *
     * @param {object} params parameters
     * @param {boolean}  [params.isAutomaticUpdate = false] Tell if the patches to get are for an automatic update
     * @returns {object[]} The patches to use for update
     */
    $scope.getPatches = function ({ isAutomaticUpdate = false } = {}) {
      const patches = demandesPaiementService.retrieveUpdatePatches({
        demandePaiementUpdated: $scope.demandePaiement,
        originalDemandePaiement: $scope.originalDemandePaiement,
      });

      if (!isAutomaticUpdate || !$scope.sharedWithChild.calculatePatchesForAutomaticUpdate) {
        return patches;
      }

      return $scope.sharedWithChild.calculatePatchesForAutomaticUpdate({ patches });
    };

    /**
     * Save a demande-paiement with the possibility to have an action before doing it
     *
     * @param {object} params parameters
     * @param {boolean} [params.catchEventualErrors = true] True if we must catch the errors send by the update
     * @param {boolean}  [params.isAutomaticUpdate = false] True if the save is an automatic one
     * @returns {Promise<void>}
     */
    $scope.save = function ({ catchEventualErrors = true, isAutomaticUpdate = false } = {}) {
      return $scope.refreshDemandePaiement().then(function () {
        if ($scope.sharedWithChild.beforeUpdateDemandePaiement) {
          return $scope.sharedWithChild.beforeUpdateDemandePaiement().then(function () {
            return $scope.updateDemandePaiement({ catchEventualErrors, isAutomaticUpdate });
          });
        }

        return $scope.updateDemandePaiement({ catchEventualErrors, isAutomaticUpdate });
      });
    };

    /**
     * Update a demande-paiement
     *
     * @param {object} params parameters
     * @param {boolean} [params.catchEventualErrors = true] True if we must catch the errors send by the update
     * @param {boolean}  [params.isAutomaticUpdate = false] True if the save is an automatic one
     * @returns {Promise<void>}
     */
    $scope.updateDemandePaiement = function ({ catchEventualErrors = true, isAutomaticUpdate = false }) {
      const patches = $scope.getPatches({ isAutomaticUpdate });

      if (!patches.length) {
        return $q.resolve();
      }

      return demandesPaiementService
        .createOrUpdate({ demandePaiement: $scope.demandePaiement, patches })
        .then(function (demandePaiement) {
          // Refresh attributaire if we have one with its family,
          // otherwise we will use the one coming from expand request
          if (demandePaiement.attributaire.famille) {
            $scope.demandePaiement.attributaire = demandePaiement.attributaire;
          }

          // Update the demande-paiement for further compare
          $scope.originalDemandePaiement = angular.copy(demandePaiement);
          $scope.originalDemandePaiement.attributaire = $scope.demandePaiement.attributaire;
        })
        .catch(function (error) {
          if (!catchEventualErrors) {
            throw error;
          }

          // Disconnection of the user
          if (error?.status === 401) {
            $state.go('app.home', {
              loggedOut: true,
            });

            userSessionService.destroy();
            $rootScope.$broadcast(AUTHENTICATION_EVENTS.notAuthorized);
            return;
          } else if (error?.status === 403) {
            NotificationCenter.postMessage({
              content: $translate.instant('connected.depot.errors.demandeNoLongerAccessible'),
              variant: 'danger',
            });

            $state.go('app.connected.dashboard.accueil');
          } else {
            $log.error(error);
            $rootScope.$broadcast('alerts', alertsService.getAlertError(error.data.message));
          }
        });
    };

    if (configuration.depot?.demandePaiement?.automaticUpdate?.active) {
      /**
       * The interval making the automatic update of demande-paiement
       */
      $scope.automaticUpdate = $interval(function () {
        if (!$scope.sharedWithChild.automaticSaveActive) {
          // If the page does not need an automatic update we cancel it temporary
          return;
        }

        if ($scope.sharedWithChild.beforeAutomaticSave) {
          return $scope.sharedWithChild.beforeAutomaticSave().then(() => $scope.save({ isAutomaticUpdate: true }));
        }

        return $scope.save({ isAutomaticUpdate: true });
      }, INTERVAL_AUTOMATIC_UPDATE_SECONDS * 1_000);
    }

    $scope.$on('$destroy', function () {
      if ($scope.automaticUpdate) {
        // Interval need to be cancel when we leave the page
        $interval.cancel($scope.automaticUpdate);
      }
      if (lockingSocket) {
        socketService.close(lockingSocket);
      }
    });
    $scope.$on('entity-shared', () => {
      if (!lockingSocket) {
        lockEntitiesService.getLockingSocket(demandePaiement, 'demandes-paiement').then((socket) => {
          lockingSocket = socket;
        });
      }
    });
  },
]);
