'use strict';
/**
 * @module aides
 * @name shareEntity
 * @description
 *
 * Share a entity
 * @param {object} [entity] - entity model
 * @param {boolean} [disabled] - disable button if disabled is true
 * @param {boolean} [showButton] - show button if param is present
 * @example
 *
 *   <share-entity entity="$ctrl.entity" show-button disabled="isDisabled"></share-entity>
 */
class ShareEntityController {
  constructor(shareEntityService, $scope, $attrs, $translate) {
    this.shareEntityService = shareEntityService;
    this.$scope = $scope;
    this.$attrs = $attrs;
    this.$translate = $translate;
  }

  /**
   * Set isShared if there is at least one share
   *
   * @returns {boolean}
   */
  get isShared() {
    return this.totalShared > 0;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.showModal = false;
    this.showButton =
      Object.hasOwn(this.$attrs, 'showButton') && this.$attrs.showButton !== 'false' && !this.isClotured;
    this.hasLabel = Object.hasOwn(this.$attrs, 'hasLabel') && this.$attrs.hasLabel !== 'false';
    this.sharings = [];

    if (this.entity?.id) {
      // If totalShared is not defined, we have to fetch sharings to determine the total of sharings
      if (this.totalShared === undefined) {
        this.getSharings();
      }
    } else {
      this.$scope.$on('share-entity', (_event, entity) => this.handleOpenModalEvent(entity));
    }

    this.$scope.$on('save-sharings', (_event, sharings) => this.saveSharings(sharings));
  }

  /**
   * Init callback for open modale partage emit
   *
   * @param {object} entity
   * @returns {void}
   */
  handleOpenModalEvent = (entity) => {
    this.entity = entity;
    this.getSharings().then(this.openModal);
  };

  /**
   * Show the share-entity modal
   *
   * @returns {void}
   */
  openModal = () => {
    // If we trust totalShared on component init, we have to fetch sharings list here
    if (this.totalShared !== this.sharings?.length) {
      this.getSharings().then(() => this.$scope.$emit('open-sharing-modal', this.sharings, this.entity));
    } else {
      this.$scope.$emit('open-sharing-modal', this.sharings, this.entity);
    }
  };

  /**
   * Get shared emails list
   *
   * @returns {void}
   */
  getSharings = () => {
    this.isLoading = true;
    return this.shareEntityService
      .getSharings(this.entity)
      .then((sharings) => {
        this.sharings = sharings;
        this.totalShared = sharings.length;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  /**
   * Save sharedEmails list
   *
   * @param {string[]} sharedEmails
   * @returns {void}
   */
  saveSharings(sharedEmails) {
    this.isLoading = true;
    this.shareEntityService
      .saveSharings(this.entity, sharedEmails)
      .then((sharings) => {
        this.sharings = sharings;
        this.totalShared = sharings.length;
        this.$scope.$emit('entity-shared', this.sharings);
      })
      .catch((err) => {
        NotificationCenter.postMessage({
          content: this.$translate.instant(err?.message),
          variant: 'danger',
        });
        this.getSharings();
        this.$scope.$emit('close-sharings-modal');
      })
      .finally(() => {
        this.showModal = false;
        this.isLoading = false;
      });
  }
}
ShareEntityController.$inject = ['shareEntityService', '$scope', '$attrs', '$translate'];

angular.module('aides').component('shareEntity', {
  templateUrl: 'aides/aides-components/share-entity/share-entity.component.html',
  bindings: {
    entity: '<',
    disabled: '<',
    totalShared: '<',
    isClotured: '<',
  },

  controller: ShareEntityController,
});
