'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurRepresentantLegalController', [
  '$scope',
  'StoreService',
  'Representant',
  'tiersService',
  function ($scope, StoreService, Representant, tiersService) {
    'use strict';

    $scope.demandeur = StoreService.demandeur.get();

    tiersService.getPublicSettingsTiers().then((response) => {
      $scope.sectionAdresseContacts = response.sectionAdresseContacts;
    });

    // Initialisation du représentant légal
    // Correspond au premier représentant du tableau des représentants au niveau de l'objet tiers
    if ($scope.demandeur.representants.length === 0) {
      $scope.demandeur.representants.push(
        new Representant({
          principal: true,
        })
      );
    }

    $scope.representantLegal = _.find($scope.demandeur.representants, { principal: true });
    const metadatas = $scope.aide.history.begin.metadata;
    tiersService.setRepresentantLegalMetadata(metadatas, $scope.representantLegal);

    $scope.$watch('representantLegal.userIsRepresentant', (newValue) => {
      metadatas.representantLegal.userIsRepresentant = newValue;
    });

    $scope.$watch('representantLegal.adresseCommuneTiers', (newValue) => {
      metadatas.representantLegal.adresseCommuneTiers = newValue;
    });

    $scope.setFonctionsRepresentants($scope.demandeur.famille.expand, 'demandeur');

    $scope.cleanNavigate();
    $scope.navigate.next = () => {
      StoreService.demandeur.set($scope.demandeur);

      $scope.saveDemandeur().then(() => {
        $scope.goToStep('demandeur-representants');
      });
    };
    $scope.navigate.ns = $scope.representantLegalDemandeurConfiguration.ns;
    $scope.representantLegalDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'tiers';
  },
]);
