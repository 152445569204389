'use strict';
angular.module('aides.services').factory('avisPrealablesService', [
  '$http',
  'configuration',
  'Aide',
  function ($http, configuration, Aide) {
    'use strict';

    return {
      /**
       * Récupération de l'avis préalable du tiers passé en paramètre
       *
       * @param {object[]} avisPrealables
       * @param {object} tiers
       * @returns {object}
       */
      getAvisPrealableTiers: function (avisPrealables, tiers) {
        let avisPrealableTiers;
        if (tiers) {
          avisPrealableTiers = _.find(avisPrealables, ['tiers.href', tiers.id || tiers.href]);
        }

        return avisPrealableTiers;
      },
      /**
       * Dépôt d'un avis préalable sur une demande de financement
       *
       * @param {object} aide aide
       * @param {string} avis avis
       * @param {string} description description
       * @returns {Promise<object>} transmitted demande financement
       */
      answerAvisPrealableAndTransmitDemande: (aide, avis, description = '') => {
        const tenantId = _.get(configuration, 'tenant.id');

        return $http
          .post(
            `/referentiel-financement/api/tenants/${tenantId}/demandes-financement/${aide.reference}/avis-prealable/answer`,
            {
              avis,
              description,
            }
          )
          .then(({ data }) => data);
      },

      /**
       * Récupération d'une aide sur laquelle je dois déposer ou j'ai déposé un avis préalable
       *
       * @param {string} reference Référence de la demande de financement
       * @param {object} mdm Mdm
       * @returns {Promise<object>} aide
       */
      getContributionPourAvisPrealables: function (reference, mdm) {
        return $http
          .get(
            `/referentiel-financement/api/tenants/${configuration.tenant.id}/bff/demandes-financement/${reference}/depot-avis-prealable`
          )
          .then(function (response) {
            return new Aide(response.data, mdm);
          });
      },
    };
  },
]);
