class DepotSimpleInformationsGeneralesSectionDonneesFiscales {
  /** @type {object} */
  teleservice;

  /** @type {object} */
  aide;

  /** @type {object | null} */
  contribution;

  /** @type {boolean} */
  valid;

  constructor(
    $scope,
    $timeout,
    $window,
    configuration,
    expressionsService,
    jwtSessionService,
    $sce,
    $httpParamSerializer
  ) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.configuration = configuration;
    this.expressionsService = expressionsService;
    this.jwtSessionService = jwtSessionService;
    this.$sce = $sce;
    this.$httpParamSerializer = $httpParamSerializer;

    this.sectionConfiguration = {};
    this.state = {
      iframeFormValid: false,
      iframeUrl: '',
      iframeTitle: '',
    };

    this.donneesFiscalesSection = null;
  }

  $onInit() {
    this.sectionConfiguration =
      this.teleservice.workflow?.pageInformationsGenerales?.donneesFiscales ||
      this.teleservice.workflow?.simple?.pageInformationsGenerales?.donneesFiscales ||
      {};

    this.state.iframeTitle = this.sectionConfiguration.title;

    this.initIframeUrl();

    // Listen to messages coming from child frames
    const onIframeMessage = this.onIframeMessage.bind(this);
    this.$window.addEventListener('message', onIframeMessage);
    // We remove the event listener on scope destruction
    this.$scope.$on('$destroy', () => {
      this.$window.removeEventListener('message', onIframeMessage);
    });

    this.$scope.$on('formSubmitted', () => {
      if (!this.valid) {
        const iframe = this.donneesFiscalesSection?.[0];
        if (!iframe) {
          return;
        }
        iframe.contentWindow.postMessage(
          {
            action: 'showDepotNextButtonClickError',
          },
          '*'
        );
      }
    });
  }

  $doCheck() {
    // Recompute if the section should be displayed on each change
    // (the eval of the condition masquage could change)
    this.isDisplayed = this.#isDisplayed();

    if (!this.isDisplayed) {
      this.valid = true;
    }
  }

  /**
   * Returns if the section should be displayed
   *
   * @returns {boolean} true if the section should be displayed
   */
  #isDisplayed() {
    const isActive = this.sectionConfiguration?.actif === true;
    const conditionMasquage = this.sectionConfiguration?.conditionMasquage;

    return (
      isActive && (!conditionMasquage || !this.expressionsService.evaluateExpression(conditionMasquage, this.aide))
    );
  }

  /**
   * Handle messages from iframe
   * depotDonneesFiscalesValid event will update the 'valid' state
   *
   * @param {object} event event
   */
  onIframeMessage(event) {
    const action = event?.data?.action;
    const depotDonneesFiscalesValid = event?.data?.depotDonneesFiscalesValid;
    const donneesFiscalesHref = event?.data?.donneesFiscalesHref;
    const donneesFiscalesData = event?.data?.donneesFiscalesData;

    if (action === 'forceResize') {
      this.donneesFiscalesSection?.[0]?.iFrameResizer?.resize();
    } else if (action === 'depotDonneesFiscalesValid' && typeof depotDonneesFiscalesValid === 'boolean') {
      this.valid = depotDonneesFiscalesValid;
    } else if (action === 'donneesFiscalesChanged') {
      if (donneesFiscalesHref && typeof donneesFiscalesHref === 'string') {
        if (!this.aide.externalData) {
          this.aide.externalData = {};
        }
        this.aide.externalData.avisImposition = {
          href: donneesFiscalesHref,
          expand: donneesFiscalesData || {},
        };
      } else if (donneesFiscalesHref === null) {
        delete this.aide.externalData?.avisImposition;
      }
    }
  }

  /**
   * Calculate the iFrame url from the aide and stateParams.referenceContrib
   *
   */
  initIframeUrl() {
    let entityKind = 'demandes-financement';
    let baseUrl = this.configuration.aides.v2.url;

    if (this.contribution && this.contribution.reference) {
      entityKind = 'contributions';
      baseUrl += `/${this.contribution.reference}`;
    } else {
      baseUrl += `/${this.aide.reference}`;
    }

    const controleCompletude = this.teleservice?.controleCompletudeDepot ?? false;

    const avisImpositionHref = this.aide?.externalData?.avisImposition?.href || null;
    const queryParams = this.$httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      entityKind,
      teleserviceReferenceWithRevision: `${this.teleservice.reference}?date=${this.teleservice.date}`,
      controleBloquantFinDepot: controleCompletude,
      initEntityHref: avisImpositionHref,
    });

    const iframeUrl = `${baseUrl}/depot-donnees-fiscales?${queryParams}`;
    this.state.iframeUrl = this.$sce.trustAsResourceUrl(iframeUrl);
  }

  /**
   * Actions on iframe load
   * (attach a resizer)
   *
   * The iframe may load multiple times, because the section has a condition masquage
   */
  onIframeLoad() {
    this.$timeout(() => {
      const iframe = this.donneesFiscalesSection?.[0];

      if (iframe) {
        iFrameResize(
          {
            heightCalculationMethod: 'lowestElement',
            checkOrigin: false,
            inPageLinks: true,
          },
          iframe
        );
      }
    });
  }
}

DepotSimpleInformationsGeneralesSectionDonneesFiscales.$inject = [
  '$scope',
  '$timeout',
  '$window',
  'configuration',
  'expressionsService',
  'jwtSessionService',
  '$sce',
  '$httpParamSerializer',
];

angular.module('portailDepotDemandeAide.depot').component('depotSimpleInformationsGeneralesSectionDonneesFiscales', {
  controller: DepotSimpleInformationsGeneralesSectionDonneesFiscales,
  templateUrl: 'depot/simple/informations-generales/donnees-fiscales-section/donnees-fiscales-section.component.html',
  bindings: {
    teleservice: '<',
    aide: '=',
    contribution: '<',
    valid: '=?',
  },
});
