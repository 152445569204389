/**
 * @param {object} aide
 * @param {string} errorMessage
 * @returns {object}
 */
function checkAndGetBeneficiaireExpand(aide, errorMessage) {
  const beneficiaire = aide.beneficiaires?.[0]?.expand;
  if (beneficiaire && (!beneficiaire.id || (beneficiaire.status !== 'TEMPORARY' && !beneficiaire.title))) {
    const currentStep = aide.history.begin.metadata.step;
    const currentStepIndex = aide.history.begin.metadata.stepsStack.indexOf(currentStep);
    const fillInTiersTitleStepIndex = aide.history.begin.metadata.stepsStack.indexOf('beneficiaire-identification');

    if (currentStepIndex > fillInTiersTitleStepIndex) {
      NotificationCenter.postMessage({
        content: errorMessage,
        variant: 'danger',
      });
      throw new Error('Expanded beneficiaire has no id or title');
    }
  }
  return beneficiaire;
}

const isDemandeurReadOnly = [
  'tiersService',
  'demandeur',
  'isAccessedThroughSharing',
  (tiersService, demandeur, isAccessedThroughSharing) => {
    return isAccessedThroughSharing && demandeur
      ? tiersService.canModifyTiers(demandeur.id).then((result) => !result)
      : false;
  },
];

const isBeneficiaireReadOnly = [
  'tiersService',
  'isDemandeurReadOnly',
  'beneficiaire',
  'isAccessedThroughSharing',
  (tiersService, isDemandeurReadOnly, beneficiaire, isAccessedThroughSharing) => {
    // If no beneficiaire is set, use right on demandeur
    if (!beneficiaire) return isDemandeurReadOnly;
    /**
     * beneficiaire is editable for demandeur (if !isAccessedThroughSharing or !isDemandeurReadOnly)
     * and for users with edit rights on beneficiaire
     */
    return isAccessedThroughSharing && isDemandeurReadOnly
      ? tiersService.canModifyTiers(beneficiaire.id).then((result) => !result)
      : false;
  },
];

/**
 * @param {string} module
 * @param {string} [view]
 * @param {string} [namespace]
 * @returns {(string | Function)[]}
 */
function resolveViewConfiguration(module, view, namespace) {
  return [
    'viewManagerService',
    '$rootScope',
    function (viewManagerService, $rootScope) {
      return $rootScope.configurationPromise.then(() => {
        return viewManagerService.getViewConfiguration(module, view, 'teleservice.' + namespace);
      });
    },
  ];
}

const declarationCompteSignataire = [
  '$stateParams',
  'teleservicesService',
  'teleserviceConfiguration',
  ($stateParams, teleservicesService, teleserviceConfiguration) => {
    if (teleserviceConfiguration) return teleserviceConfiguration.declarationCompteSignataire;

    return teleservicesService
      .getTeleService($stateParams.configurationId, false)
      .then(({ declarationCompteSignataire }) => declarationCompteSignataire);
  },
];

const tiersPhysiquesAlreadyLinked = [
  '$q',
  'tiersService',
  'mdm',
  'tiers',
  ($q, tiersService, mdm, tiers) => {
    return tiersService
      .getAllCurrentUserTiers()
      .then((allTiers) => {
        return allTiers.filter((tiers) => tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE');
      })
      .then((tiersPhysiques) => {
        return Promise.all(
          tiersPhysiques.map((tiersPhysique) => {
            //! don't fetch tiers twice
            if (tiers && tiers.id === tiersPhysique.id) return $q.resolve(tiers);
            return tiersService.getTiersById(tiersPhysique.id, mdm);
          })
        );
      });
  },
];

const teleserviceConfigurationWithRevision = [
  '$location',
  'teleservicesService',
  'aide',
  ($location, teleservicesService, aide) => {
    const persistence = $location.search().p ? true : false;

    const teleserviceAsUrl = new URL(aide.teleservice.href, 'http://localhost');
    const teleserviceReference = teleserviceAsUrl.pathname.split('/').pop();

    const teleserviceParams = { date: teleserviceAsUrl.searchParams.get('date'), expand: 'thematiques' };
    if (aide.teleserviceExtension) teleserviceParams.teleserviceExtension = aide.teleserviceExtension.href;

    return teleservicesService
      .getTeleService(teleserviceReference, persistence, teleserviceParams)
      .then((teleservice) => {
        const teleserviceType = teleservice.workflow.type;
        // ! Everywhere in depot, the property "simple" is not used and we access directly a page config from "teleservice.workflow[page]" instead of "teleservice.workflow.simple[page]"
        return { ...teleservice, workflow: { ...teleservice.workflow[teleserviceType], type: teleserviceType } };
      });
  },
];

export const depotSimpleState = {
  // Procedure model 'simple', routing between pages inside the procedure is done using includes
  // in order to prevent bugs in the workflow caused by browser navigation actions
  url: '/simple?p&fromEchange&user&theme',
  controller: 'depotSimpleController',
  templateUrl: 'depot/simple/depot-simple.html',
  reloadOnSearch: false, // ?p will be set after some time, do not reload the state for it
  resolve: {
    aide: [
      '$state',
      '$stateParams',
      '$translate',
      'ngToast',
      'configuration',
      'aidesService',
      'userSessionService',
      'teleserviceConfiguration',
      'teleservicesService',
      'Aide',
      'mdm',
      (
        $state,
        $stateParams,
        $translate,
        ngToast,
        configuration,
        aidesService,
        userSessionService,
        teleserviceConfiguration,
        teleservicesService,
        Aide,
        mdm
      ) => {
        const teleserviceUrl = teleserviceConfiguration.id;
        const user = userSessionService.getUser();
        if ($stateParams.p) {
          const aideReference = $stateParams.p;
          const expands = [
            'teleservice',
            'teleservice.expand.thematiques',
            'demandeur.expand.famille',
            'beneficiaires.expand.famille',
            'pieces.documents',
            'specifiques.value',
            'planFinancement.depense.postes.lignes.pieces.documents',
            'domiciliationBancaire.pieces.documents',
            'bourse',
            'externalData.avisImposition',
          ].join(',');
          return aidesService
            .get(aideReference, mdm, expands)
            .then(function (aide) {
              if (!['REQUESTED', 'WAITING_FOR_CERTIFICAT', 'REGISTERED'].includes(aide.status)) {
                $state.go('app.connected.dashboard.accueil');
                return;
              }

              if (aide.teleserviceExtension) {
                return teleservicesService
                  .getTeleService(teleserviceConfiguration.reference, null, {
                    teleserviceExtension: aide.teleserviceExtension.href,
                  })
                  .then(function (teleserviceWithExtension) {
                    // remove the "workflow.simple" key to match the teleserviceConfiguration
                    teleserviceWithExtension.workflow =
                      teleserviceWithExtension.workflow[teleserviceConfiguration.workflow.type];
                    teleserviceWithExtension.workflow.type = teleserviceConfiguration.workflow.type;
                    Object.assign(teleserviceConfiguration, teleserviceWithExtension);
                    return aide;
                  });
              } else {
                return aide;
              }
            })
            .catch((err) => {
              let message =
                err.status === 410 ? 'connected.depot.errors.demandeDeleted' : 'connected.depot.errors.unknown';

              ngToast.create({
                content: $translate.instant(message),
                className: 'danger',
                timeout: 5000,
                maxNumber: 1,
              });

              const isCurrentRouteSharedAides = $state.current.name.includes('sharedAides');
              $state.go(
                'app.connected.dashboard.aides.demandesAides.' + (isCurrentRouteSharedAides ? 'sharedAides' : 'aides'),
                $stateParams
              );
            });
        } else {
          return new Aide({
            status: 'REQUESTED',
            dispositif: teleserviceConfiguration.dispositif,
            user: {
              title: user.displayName,
              rel: 'user',
              href: configuration.user.accountManagement + /users/ + user.userName,
            },

            teleservice: {
              id: teleserviceUrl,
              href: teleserviceUrl,
              title: teleserviceConfiguration.title,
              rel: 'teleservice',
              expand: teleserviceConfiguration,
            },

            linkedUsers: [
              {
                title: user.displayName,
                rel: 'user',
                form: 'ADMINISTRATOR',
                href: configuration.user.accountManagement + /users/ + user.userName,
              },
            ],
          });
        }
      },
    ],

    isAccessedThroughSharing: [
      'aidesService',
      'aide',
      'tiers',
      (aidesService, aide, tiers) => {
        return aidesService.isAccessedThroughSharing(aide, tiers);
      },
    ],

    demandeur: [
      '$q',
      'aide',
      'tiers',
      'Tiers',
      'isAccessedThroughSharing',
      'cmisService',
      'userSessionService',
      ($q, aide, tiers, Tiers, isAccessedThroughSharing, cmisService, userSessionService) => {
        let demandeur;
        let promises = [];
        if (tiers && !isAccessedThroughSharing) {
          /**
           * if we are attached to a tiers, then it become the demandeur of the demande
           * it is the case when we create a new demande OR edit a REQUESTED demande (that may have had a different demandeur)
           * if we are in sharing mode, we don't want to do that
           */
          demandeur = new Tiers(tiers);
        } else if (aide.demandeur.href && aide.demandeur.expand) {
          demandeur = aide.demandeur.expand;
          // In this case we don't have cmis properties in documents so we need to retrieve them
          // Only if tiers is linked to the user's demandeur
          const user = userSessionService.getUser();
          const linkedUsersHref = demandeur.linkedUsers.map(({ href }) => href);

          if (linkedUsersHref.includes(user.self)) {
            demandeur.domiciliationsBancaires?.forEach((domiciliationBancaire) => {
              domiciliationBancaire?.pieces?.forEach((piece) => {
                piece?.documents?.forEach((document) => {
                  const promise = cmisService.expandDocument(document);
                  promises.push(promise);
                });
              });
            });
          }
        }
        return $q.all(promises).then(() => demandeur);
      },
    ],

    beneficiaire: [
      '$translate',
      'aide',
      ($translate, aide) => {
        return checkAndGetBeneficiaireExpand(aide, $translate.instant('connected.depot.errors.unknown'));
      },
    ],

    lockingSocket: [
      '$state',
      '$stateParams',
      '$translate',
      'lockEntitiesService',
      'aide',
      ($state, $stateParams, $translate, lockEntitiesService, aide) => {
        return lockEntitiesService.getLockingSocket(aide, 'demandes-financement').catch((err) => {
          $state.go('app.connected.dashboard.recapitulatif', {
            aide: aide.reference,
            tenantId: $stateParams.tenantId,
          });
          NotificationCenter.postMessage({
            content: $translate.instant('connected.depot.errors.demandeLockedByAnotheruser', err.data),
            variant: 'danger',
          });
        });
      },
    ],

    isDemandeurReadOnly,

    isBeneficiaireReadOnly,

    tiersUsedForDepot: [
      'aide',
      'tiers',
      'isAccessedThroughSharing',
      (aide, tiers, isAccessedThroughSharing) => {
        if (isAccessedThroughSharing) {
          /**
           * If sharing mode and demandeur is not validated,
           * act as no tiers to display the tiers form
           */
          return aide.demandeur?.expand?.status === 'TEMPORARY' ? null : aide.demandeur.expand;
        }
        return tiers;
      },
    ],

    // Simple
    simpleConfiguration: resolveViewConfiguration('simple'),

    // Public Settings Financement
    publicSettingsFinancement: [
      'aidesService',
      (aidesService) => {
        return aidesService.getPublicSettingsFinancement();
      },
    ],

    contribution: [
      'contributionsFactory',
      (contributionsFactory) => {
        return contributionsFactory.setContribution(null);
      },
    ],

    declarationCompteSignataire,

    attestationDeclarationHonneur: [
      'configuration',
      '$http',
      (configuration, $http) => {
        return $http.get(configuration.referentielPiece.url + '/attestationDeclarationHonneur').then(function (res) {
          return res.data;
        });
      },
    ],

    echangesActif: [
      'aide',
      'teleservicesService',
      'isAccessedThroughSharing',
      (aide, teleservicesService, isAccessedThroughSharing) => {
        // PLAID-66072, RG 2-08: no access to echanges in sharing mode
        if (isAccessedThroughSharing) return false;

        return teleservicesService.getTeleServiceFromObject(aide, true).then(function (teleservice) {
          return teleservice.echangesActif;
        });
      },
    ],
    tiersPhysiquesAlreadyLinked,
    teleserviceOpeningData: [
      '$q',
      'aidesService',
      'aide',
      ($q, aidesService, aide) => {
        const promise = aide.id ? aidesService.isOpen(aide.id) : $q.resolve(aide.teleservice.expand._meta);
        return promise;
      },
    ],
    publicSettingsTiers: ['publicSettingsTiers', (publicSettingsTiers) => publicSettingsTiers],
    teleserviceConfigurationWithRevision,
  },

  onEnter: [
    '$rootScope',
    'aide',
    'echangesActif',
    function ($rootScope, aide, echangesActif) {
      $rootScope.echangesActif = echangesActif;
      if (aide.reference) {
        $rootScope.displayEchangeMenu = {
          value: echangesActif,
          demandeId: aide.reference,
        };
      }
    },
  ],
  onExit: [
    '$rootScope',
    function ($rootScope) {
      $rootScope.displayEchangeMenu = {
        value: false,
      };
    },
  ],
};

export const contributionModificationState = {
  // Procedure model 'simple', routing between pages inside the procedure is done using includes
  // in order to prevent bugs in the workflow caused by browser navigation actions
  url: '/contributionModification?p&c',
  controller: 'depotSimpleController',
  templateUrl: 'depot/simple/depot-simple.html',
  reloadOnSearch: false, // ?p will be set after some time, do not reload the state for it
  resolve: {
    // Contribution
    contribution: [
      '$stateParams',
      '$state',
      '$translate',
      'contributionsService',
      'contributionsFactory',
      'contributionsConstants',
      ($stateParams, $state, $translate, contributionsService, contributionsFactory, contributionsConstants) => {
        return contributionsService
          .get($stateParams.c)
          .then((contribution) => {
            if (contribution && contribution.statut === contributionsConstants.status.ANSWERED) {
              contributionsService.notifications.alreadyDone();
              return $state.go('app.connected.dashboard.accueil', { tenantId: $stateParams.tenantId });
            }

            contributionsFactory.setContribution(contribution);

            return contribution;
          })
          .catch((err) => {
            let message = err.status
              ? 'connected.depot.errors.demandeNoLongerAccessible'
              : 'connected.depot.errors.unknown';

            NotificationCenter.postMessage({
              content: $translate.instant(message),
              variant: 'danger',
            });

            $state.go('app.connected.dashboard.accueil', { tenantId: $stateParams.tenantId }, { reload: true });

            throw err;
          });
      },
    ],

    aide: [
      '$stateParams',
      'mdm',
      'teleservicesService',
      'teleserviceConfiguration',
      'aidesService',
      ($stateParams, mdm, teleservicesService, teleserviceConfiguration, aidesService) => {
        const aideReference = $stateParams.p;
        const contributionReference = $stateParams.c;
        const expands = [
          'teleservice',
          'teleservice.expand.thematiques',
          'demandeur.expand.famille',
          'beneficiaires.expand.famille',
          'pieces.documents',
          'specifiques.value',
          'planFinancement.depense.postes.lignes.pieces.documents',
          'domiciliationBancaire.pieces.documents',
          'bourse',
          'externalData.avisImposition',
        ].join(',');
        return aidesService.getAideWithContribution(aideReference, contributionReference, mdm, expands).then((aide) => {
          if (aide.teleserviceExtension) {
            return teleservicesService
              .getTeleService(teleserviceConfiguration.reference, null, {
                teleserviceExtension: aide.teleserviceExtension.href,
              })
              .then((teleserviceWithExtension) => {
                // remove the "workflow.simple" key to match the teleserviceConfiguration
                teleserviceWithExtension.workflow =
                  teleserviceWithExtension.workflow[teleserviceConfiguration.workflow.type];
                teleserviceWithExtension.workflow.type = teleserviceConfiguration.workflow.type;
                Object.assign(teleserviceConfiguration, teleserviceWithExtension);
                return aide;
              });
          } else {
            return aide;
          }
        });
      },
    ],

    demandeur: [
      '$translate',
      'aide',
      'ngToast',
      ($translate, aide, ngToast) => {
        const demandeur = aide.demandeur?.expand;
        if (demandeur && (!demandeur.id || !demandeur.title)) {
          ngToast.create({
            content: $translate.instant('connected.depot.errors.contributionAccessError'),
            className: 'danger',
            timeout: 5000,
            maxNumber: 1,
          });
          throw new Error('Expanded demandeur has no id or title');
        }
        return demandeur;
      },
    ],

    beneficiaire: [
      '$translate',
      'aide',
      ($translate, aide) => {
        return checkAndGetBeneficiaireExpand(
          aide,
          $translate.instant('connected.depot.errors.contributionAccessError')
        );
      },
    ],

    // Simple
    simpleConfiguration: resolveViewConfiguration('simple'),

    // Public Settings Financement
    publicSettingsFinancement: [
      'aidesService',
      (aidesService) => {
        return aidesService.getPublicSettingsFinancement();
      },
    ],

    declarationCompteSignataire,

    attestationDeclarationHonneur: [
      'configuration',
      '$http',
      (configuration, $http) => {
        return $http
          .get(configuration.referentielPiece.url + '/attestationDeclarationHonneur')
          .then(({ data }) => data);
      },
    ],

    tiersPhysiquesAlreadyLinked,

    isAccessedThroughSharing: [
      'contributionsService',
      'contribution',
      (contributionsService, contribution) => {
        return contributionsService.isAccessedThroughSharing(contribution);
      },
    ],

    lockingSocket: [
      '$state',
      '$stateParams',
      '$translate',
      'lockEntitiesService',
      'aide',
      'contribution',
      ($state, $stateParams, $translate, lockEntitiesService, aide, contribution) => {
        return lockEntitiesService.getLockingSocket(contribution, 'contributions').catch((err) => {
          $state.go('app.connected.dashboard.recapitulatif', {
            aide: aide.reference,
            tenantId: $stateParams.tenantId,
          });
          NotificationCenter.postMessage({
            content: $translate.instant('connected.depot.errors.demandeLockedByAnotheruser', err.data),
            variant: 'danger',
          });
        });
      },
    ],

    isDemandeurReadOnly,

    isBeneficiaireReadOnly,

    tiersUsedForDepot: [
      'demandeur',
      'isAccessedThroughSharing',
      (demandeur, isAccessedThroughSharing) => {
        /**
         * If sharing mode, use demandeur as tiers to display the tiers page
         */
        return isAccessedThroughSharing ? demandeur : null;
      },
    ],

    teleserviceOpeningData: () => null,
    teleserviceConfigurationWithRevision,
  },
};

export const contributionRedirectionState = {
  ...contributionModificationState,
  url: '/contributionRedirection?p&c',
  resolve: { ...contributionModificationState.resolve, teleserviceConfigurationWithRevision: () => null },
};
